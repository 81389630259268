<template>
  <v-footer dark padless> </v-footer>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    };
  },
  computed: {
    theme() {
      return this.$store.getters.isThemeDark
        ? "grey darken-3"
        : "grey lighten-4";
    },
  },
};
</script>