import "@babel/polyfill";
import Vue from "vue";
import "@/helpers/prototype";
import longpress from "@/plugins/longpress";
import vuetify from "@/plugins/vuetify";
import vuetip from "@/plugins/vuetip";
import VueOnlineProp from "@/plugins/vue-online-prop";
import mathjax from "@/plugins/mathjax";
import localforage from "@/plugins/localforage";
import moment from "@/plugins/moment";
import VueYouTubeEmbed from "@/plugins/vue-youtube-embed";
import VueVirtualScroller from "@/plugins/vue-virtual-scroller";
import "@/plugins/Html2Canvas";
import vtooltip from "@/plugins/vtooltip";
import VueCarousel from "@/plugins/vue-carousel";
import LottieAnimation from "@/plugins/lottie-animation";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/helpers/persist";
import { axios } from "@/plugins/axios";
import { locales } from "@/locales";
import "@/registerServiceWorker";
import "@/plugins/deeplink";
import { Device } from "@capacitor/device";
import { initialize } from "@/plugins/admob/index.js";
import Clipboard from "@/plugins/clipboard";
// import "mathjax";
import "@codetrix-studio/capacitor-google-auth";
import "animate.css";

Vue.config.productionTip = false;

// This is a global mixin, it is applied to every vue instance.
// Mixins must be instantiated *before* your call to new Vue(...)
Vue.mixin({
  data() {
    return {
      // get isHindi() {
      //   return store.getters.getCurrLocale == "hi";
      // },
    };
  },
  computed: {
    isDark() {
      return store.getters.isThemeDark;
    },
    isHindi() {
      return store.getters.getCurrLocale == "hi";
    },
    isEnglish() {
      return store.getters.getCurrLocale == "en";
    },
    currLocale() {
      return store.getters.getCurrLocale;
    },
  },
  async created() {
    this.info = await Device.getInfo();
    if (this.info && this.info.platform != "web") {
      await initialize();
    }
  },
});

new Vue({
  router,
  vuetip,
  store,
  vuetify,
  localforage,
  axios,
  locales,
  longpress,
  mathjax,
  moment,
  VueOnlineProp,
  VueYouTubeEmbed,
  vtooltip,
  VueVirtualScroller,
  VueCarousel,
  LottieAnimation,
  Clipboard,
  render: (h) => h(App),
}).$mount("#app");

if (false) {
  // disable right click inspect etc
  document.addEventListener(
    "keydown",
    function() {
      if (event.keyCode == 123) {
        // alert("You Can not Do This!");
        return false;
      } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
        // alert("You Can not Do This!");
        return false;
      } else if (event.ctrlKey && event.keyCode == 85) {
        // alert("You Can not Do This!");
        return false;
      }
    },
    false
  );

  if (document.addEventListener) {
    document.addEventListener(
      "contextmenu",
      function(e) {
        // alert("You Can not Do This!");
        e.preventDefault();
      },
      false
    );
  } else {
    document.attachEvent("oncontextmenu", function() {
      // alert("You Can not Do This!");
      window.event.returnValue = false;
    });
  }
}
