<template>
  <v-list v-if="deviceInfo && deviceInfo.platform != 'web'">
    <v-divider></v-divider>
    <v-list-item @click="goToPlayStore" v-tooltip="`Open in Play Store.`">
      <v-list-item-content>
        <v-list-item-title>{{
          isHindi ? "एप संस्करण" : "App Version"
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>{{
        appInfo ? appInfo.version : ""
      }}</v-list-item-action>
    </v-list-item>
  </v-list>
  <v-list v-else>
    <v-divider></v-divider>
    <v-list-item @click.stop="goToPlayStore()">
      <v-list-item-avatar>
        <v-icon> mdi-google-play </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <span>&nbsp;Get it from Play Store</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import bus from "@/bus";
import { App as CapacitorApp } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { Browser } from "@capacitor/browser";
export default {
  name: "app-version",
  data() {
    return {
      appInfo: null,
      deviceInfo: null,
    };
  },
  methods: {
    async goToPlayStore() {
      let appStoreURL =
        "https://play.google.com/store/apps/details?id=com.vikarn.app.client";
      await Browser.open({ url: appStoreURL });
    },
  },
  async created() {
    this.deviceInfo = await Device.getInfo();
    if (this.deviceInfo && this.deviceInfo.platform != "web") {
      this.appInfo = await CapacitorApp.getInfo();
    }
  },
};
</script>

<style>
</style>