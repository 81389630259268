const roles = [
  "admin",
  "superadmin",
  "manager",
  "moderator",
  "teacher",
  "parent",
  "student",
  "member",
];
export default [
  {
    path: "/games/signs-practice",
    name: "signs-practice",
    component: () => import("../components/MainIndex"),
    meta: {
      title: "Signs_Practice",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
