import auth from "./auth/routes";
import home from "./home/routes";
import profile from "./profile/routes";
import exams from "./exams/routes";
import notes from "./notes/routes";
import quotes from "./quotes/routes";
import jobs from "./jobs/routes";
import doubts from "./doubts/routes";
import quiz from "./quiz/routes";
import payment from "./payment/routes";
import learn from "./learn/routes";
import tags from "./tags/routes";
import iap from "./iap/routes";
import graph from "./graph/routes";
import feed from "./feed/routes";
import notifications from "./notifications/routes";
import videos from "./videos/routes";
import rewards from "./rewards/routes";
import ugcp from "./ugcp/routes";
import errors from "./errors/routes";

export default [
  ...auth,
  ...home,
  ...exams,
  ...notes,
  ...quotes,
  ...jobs,
  // ...doubts, // removed for ugcp
  ...quiz,
  ...payment,
  ...learn,
  ...tags,
  ...iap,
  ...graph,
  ...feed,
  ...notifications,
  ...videos,
  ...rewards,
  ...ugcp,
  ...errors,
  ...profile, // <-- put it at the end. if no url would match it will try to match it with user profile
];
