export default [
  {
    path: "/graph",
    name: "graph",
    component: () => import("../components/Graph.vue"),
    meta: {
      title: "Graph",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
];
