<template>
  <div>
    <v-app-bar v-if="visible" app>
      <v-app-bar-nav-icon
        v-if="showIcon"
        @click.stop="$emit('toggle-drawer')"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $lang(pageTitle ? pageTitle : "APP_TITLE")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- right buttons -->
      <context-button />
      <v-btn
        class="animate__animated animate__swing animate__delay-1s animate__slow"
        icon
        @click.stop="showRightDrawer = !showRightDrawer"
      >
        <v-icon>mdi-bell</v-icon>
        <v-badge
          v-if="authUser && authUser.__meta__.notifications_count > 0"
          :content="authUser.__meta__.notifications_count"
          top
          right
          offset-y="2"
          offset-x="12"
          color="red"
        ></v-badge>
      </v-btn>
    </v-app-bar>
    <AppDrawerRight
      @drawer="(stat) => (showRightDrawer = stat)"
      :show="showRightDrawer"
    />
  </div>
</template>

<script>
import ContextButton from "@/components/ContextButton";
import AppDrawerRight from "@/app/notifications/modules/AppDrawerRight.vue";
import bus from "@/bus";
import { mapGetters } from "vuex";

export default {
  props: ["pageTitle"],
  components: {
    ContextButton,
    AppDrawerRight,
  },
  data() {
    return {
      notificationCount: 10,
      showRightDrawer: false,
      visible: true,
      showIcon: true,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
  },
  watch: {
    "$route.name"(name) {
      if (["login", "register", "forgot-password"].includes(name)) {
        this.showIcon = false;
      } else {
        this.showIcon = true;
      }
    },
    auth(val) {},
    pageTitle(val) {
      if (!val) return;
      document.title = this.$lang(val) + " | " + this.$lang("APP_TITLE");
    },
  },
  mounted() {
    bus.$on("hideAppBar", () => {
      this.visible = false;
      $emit("hide-drawer");
    });
    bus.$on("showAppBar", () => {
      this.visible = true;
    });
  },
};
</script>

<style>
</style>