import localforage from "localforage";
import { isEmpty } from "lodash";

export const RESET_STATE = (state) => {
  //state.exams = [];
};

// export const SET_EXAMS = (state, data) => {
//   state.exams = data;
// };
