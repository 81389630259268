import auth from "./auth/locales";
import home from "./home/locales";
import profile from "./profile/locales";
import exams from "./exams/locales";
import notes from "./notes/locales";
import quotes from "./quotes/locales";
import jobs from "./jobs/locales";
import doubts from "./doubts/locales";
import quiz from "./quiz/locales";
import payment from "./payment/locales";
import learn from "./learn/locales";
import tags from "./tags/locales";
import iap from "./iap/locales";
import graph from "./graph/locales";
import feed from "./feed/locales";
import notifications from "./notifications/locales";
import videos from "./videos/locales";
import rewards from "./rewards/locales";
import ugcp from "./ugcp/locales";
import errors from "./errors/locales";

export default {
  en: {
    ...auth.en,
    ...home.en,
    ...profile.en,
    ...exams.en,
    ...notes.en,
    ...quotes.en,
    ...jobs.en,
    ...doubts.en,
    ...quiz.en,
    ...payment.en,
    ...learn.en,
    ...tags.en,
    ...iap.en,
    ...graph.en,
    ...feed.en,
    ...notifications.en,
    ...videos.en,
    ...rewards.en,
    ...ugcp.en,
    ...errors.en,
  },
  hi: {
    ...auth.hi,
    ...home.hi,
    ...profile.hi,
    ...exams.hi,
    ...notes.hi,
    ...quotes.hi,
    ...jobs.hi,
    ...doubts.hi,
    ...quiz.hi,
    ...payment.hi,
    ...learn.hi,
    ...tags.hi,
    ...iap.hi,
    ...graph.hi,
    ...feed.hi,
    ...notifications.hi,
    ...videos.hi,
    ...rewards.hi,
    ...ugcp.hi,
    ...errors.hi,
  },
};
