import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";

export const registerMobile = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/signup/mobile", user)
    .then((response) => {
      bus.$emit("hideWait");
      return dispatch("setToken", response.data.token).then(() => {
        return dispatch("fetchUser")
          .then((user) => {
            return Promise.resolve(response);
          })
          .catch((err) => {
            bus.$emit("hideWait");
            return Promise.reject(err.response);
          });
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const loginMobile = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/authorise/mobile", user)
    .then((response) => {
      return dispatch("setToken", response.data.token).then(() => {
        return dispatch("fetchUser")
          .then((user) => {
            return Promise.resolve(response);
          })
          .catch((err) => {
            bus.$emit("hideWait");
            return Promise.reject(err.response);
          });
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const register = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/signup", user)
    .then((response) => {
      bus.$emit("hideWait");
      return dispatch("login", { user }).then(() => {
        return Promise.resolve(response.data.data);
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const loginSignupWithGoogle = ({ dispatch }, { access_token }) => {
  bus.$emit("showWait");
  return axios
    .get(
      `auth/authenticated/google/with/token/?access_token=${encodeURIComponent(
        access_token
      )}`
    )
    .then((response) => {
      return dispatch("setToken", response.data.token).then(() => {
        return dispatch("fetchUser")
          .then((user) => {
            return Promise.resolve(response);
          })
          .catch((err) => {
            bus.$emit("hideWait");
            return Promise.reject(err.response);
          });
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const loginSignupWithGoogleAuthCode = ({ dispatch }, { code }) => {
  bus.$emit("showWait");
  return axios
    .get(`auth/authenticated/google/?code=${encodeURIComponent(code)}`)
    .then((response) => {
      return dispatch("setToken", response.data.token).then(() => {
        return dispatch("fetchUser")
          .then((user) => {
            return Promise.resolve(response);
          })
          .catch((err) => {
            bus.$emit("hideWait");
            return Promise.reject(err.response);
          });
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const login = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/authorise", user)
    .then((response) => {
      return dispatch("setToken", response.data.token).then(() => {
        return dispatch("fetchUser")
          .then((user) => {
            // return localforage.setItem("user", user).then(() => {
            //   dispatch("setUser", user);
            //   bus.$emit("hideWait");
            //   return Promise.resolve(response);
            // });
            return Promise.resolve(response);
          })
          .catch((err) => {
            bus.$emit("hideWait");
            return Promise.reject(err.response);
          });
      });
    })
    .catch((error) => {
      bus.$emit("hideWait");
      return Promise.reject(error.response);
    });
};

export const resentEmailVerificationCode = (
  { dispatch },
  { user, context }
) => {
  bus.$emit("showWait");
  return axios
    .post("auth/resend/email/verification/code", { email: user.email })
    .then((response) => {
      dispatch("fetchUser");
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const resentMobileVerificationCode = (
  { dispatch },
  { user, context }
) => {
  bus.$emit("showWait");
  return axios
    .post("auth/resend/mobile/verification/code", user)
    .then((response) => {
      dispatch("fetchUser");
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const logout = ({ dispatch }) => {
  bus.$emit("showWait", "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT|LOGOUT");

  return axios
    .get("auth/revoke/token?isRevokeAll=1")
    .then(() => {
      dispatch("clearAuth");
      bus.$emit("hideWait");
    })
    .catch((err) => {
      dispatch("clearAuth");
      bus.$emit("hideWait");
    });
};

export const verifyMobile = ({ dispatch }, token) => {
  bus.$emit("showWait");
  return axios
    .get("auth/confirm/mobile/" + token)
    .then((response) => {
      dispatch("fetchUser").then((user) => {
        return Promise.resolve(response);
      });
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const verifyEmail = ({ dispatch }, salt) => {
  bus.$emit("showWait");
  return axios
    .get("auth/confirm/email/" + salt)
    .then((response) => {
      dispatch("fetchUser").then((user) => {
        return Promise.resolve(response);
      });
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const forgotPassword = (_, { user, context }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/forgot/password/", user)
    .then((response) => {
      bus.$emit("hideWait");

      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const forgotPasswordMobile = (_, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/forgot/password-mobile", user)
    .then((response) => {
      bus.$emit("hideWait");

      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const recoverPasswordMobile = (_, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/reset/password-mobile", user)
    .then((response) => {
      bus.$emit("hideWait");

      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response);
    });
};

export const recoverPassword = (_, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/reset/password", user)
    .then((response) => {
      bus.$emit("hideWait");

      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response);
    });
};

export const updateEmail = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/update/email/", user)
    .then((response) => {
      dispatch("fetchUser");

      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const removeAccount = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/remove/account/", user)
    .then((response) => {
      dispatch("clearAuth");
      
      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const updateMobile = ({ dispatch }, { user }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/update/mobile/", user)
    .then((response) => {
      dispatch("fetchUser");

      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};
export const updateProfile = ({ dispatch }, { profile, password, context }) => {
  bus.$emit("showWait");
  return axios
    .post("profile/update/", { ...profile, password })
    .then((response) => {
      dispatch("fetchUser");
      // dispatch("me");

      bus.$emit("hideWait");
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const updatePassword = (_, { user, context }) => {
  bus.$emit("showWait");
  return axios
    .post("auth/update/password/", user)
    .then((response) => {
      bus.$emit("hideWait");
      //dispatch("logout"); // this wont redirect to login
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      bus.$emit("hideWait");
      return Promise.reject(err.response.data);
    });
};

export const updateAvatar = ({ dispatch }, { file, context }) => {
  if (!file.files[0]) {
    dispatch(
      "setAlert",
      { type: "error", message: "PLEASE_CHOOSE_AN_IMAGE" },
      { root: true }
    );
    return;
  }

  bus.$emit("showWait");

  var formData = new FormData();
  formData.append("avatar", file.files[0]);

  axios
    .post("profile/update/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      dispatch("fetchUser").then(() => {
        context.imagePreview = null;
        bus.$emit("hideWait");
      });
    })
    .catch((err) => {
      bus.$emit("hideWait");
      if (!err.response)
        dispatch(
          "setAlert",
          { type: "error", message: "SOMETHING_WENT_WRONG", translate: true },
          { root: true }
        );
    });
};

export const fetchUser = ({ dispatch, commit }) => {
  bus.$emit("showWait");
  return axios
    .get("auth/me")
    .then((response) => {
      commit("SET_AUTHENTICATED", true);
      commit("SET_USER", response.data);
      bus.$emit("hideWait");

      return localforage.setItem("user", response.data).then(() => {
        dispatch("app/exams/fetchAttemptedMocktests", {}, { root: true });
        bus.$emit("hideWait");
        return Promise.resolve(response.data);
      });
    })
    .catch((err) => {
      bus.$emit("hideWait");
      if (err.response) return Promise.reject("NOT_FOUND");
      else return Promise.reject("FAILED");
    });
};

export const setToken = ({ commit, dispatch }, token) => {
  if (isEmpty(token)) {
    return dispatch("checkTokenExists")
      .then((token) => {
        return setHttpToken(token);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  commit("SET_TOKEN", token);
  return setHttpToken(token);
};

export const setUser = ({ commit }, user) => {
  commit("SET_USER", user);
};

export const getUserFromLocalStorage = ({ dispatch }) => {
  return dispatch("checkTokenExists")
    .then((token) => {
      return localforage
        .getItem("user")
        .then((user) => {
          if (user && user.id) {
            return Promise.resolve(user);
          }
          return Promise.reject("NO_STORAGE_USER");
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const checkTokenExists = ({ dispatch }) => {
  return localforage.getItem("authtoken").then((authtoken) => {
    if (isEmpty(authtoken)) {
      dispatch("clearAuth");
      return Promise.reject("NO_STORAGE_TOKEN");
    }

    return Promise.resolve(authtoken);
  });
};

export const clearAuth = ({ dispatch }) => {
  dispatch("resetState", null);
  localforage.setItem("user", null);
  setHttpToken(null);
};

export const resetState = ({ commit }) => {
  commit("SET_AUTHENTICATED", false);
  commit("SET_USER", null);
  commit("SET_TOKEN", null);
};
