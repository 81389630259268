export default {
  RESULT: "परिणाम",
  EXAMS: "परीक्षा",
  EXAM: "परीक्षा",
  ATTEMPTRESULT: "टेस्ट रिजल्ट",
  HASTESTS: "कुल %s टेस्ट्स",
  DIRECTIONS: "निर्देश",
  SUBMITCONFIRMTITLE: "क्या आप तैयार हैं?",
  SUBMITCONFIRMTEXT:
    "एक बार इस टेस्ट को सबमिट करने के बाद आप दोबारा यह टेस्ट नहीं दे सकते हैं।",
  SHOWANALYSIS: "विश्लेषण देखें",
  UNLOCKINCOINS: "%s कोइन्स में अनलॉक करें ।",
  PURCHASEMOREVCOIN: "कोइन्स खरीदें",
  AVAILABLEEXAMS: "उपलब्ध परीक्षाएं",
  AVAILABLETESTS: "उपलब्ध टेस्ट्स",
  CHOOSETESTFROMBELOW: "कृपया नीचे से कोई भी परीक्षा टेस्ट चुनें।",
  CHOOSEEXAM: "कोई परीक्षा चुनिये",
  CHOOSEEXAMFROMBELOW: "कृपया नीचे से कोई भी परीक्षा श्रेणी चुनें।",
};
