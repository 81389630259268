export default [
  {
    path: "/quiz",
    name: "quiz",
    component: () => import("../components/List.vue"),
    meta: {
      title: "Quiz",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
  {
    path: "/quiz/:id",
    name: "quiz-questions",
    component: () => import("../components/Questions.vue"),
    meta: {
      title: "Quiz Questions",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
