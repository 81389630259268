export default {
  currRoute: null,
  isThemeDark: false,
  currLocale: "en",
  alert: {
    type: null,
    message: null,
    icon: "info",
    translate: false,
    isHTML: false,
    uri: null,
    prominent: false,
  },
  formErrors: {
    email: "",
    password: "",
    username: "",
    first_name: "",
    last_name: "",
  },
};
