<template>
  <v-card class="d-flex flex-column" elevation="0">
    <v-card-text
      class="text-center justify-center align-center d-flex flex-column"
    >
      <v-img class="mb-4" src="/artwork/signup.png" />
      <v-img style="width: 70%" src="/app-logo/vikarn-black-green.png" />
    </v-card-text>
    <v-card-title class="headline">{{ $lang("ChooseLanguage") }}</v-card-title>
    <v-card-text>
      <v-select
        v-model="locale"
        prepend-icon="mdi-translate"
        :items="locales"
        :label="$lang('language')"
        class="ml-2"
      ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tab-language",
  components: {},
  data() {
    return {
      locale: null,
    };
  },
  computed: {
    locales() {
      return [
        {
          text: "English",
          value: "en",
        },
        {
          text: "हिन्दी",
          value: "hi",
        },
      ];
    },
    ...mapGetters({}),
  },
  watch: {
    locale(val) {
      if (!val) return;
      this.$store.dispatch("setCurrLocale", val);
    },
  },
  async mounted() {
    setTimeout(() => {
      if (!this.locale) {
        let currLocale = this.$store.getters["getCurrLocale"];
        this.locale = currLocale ? currLocale : this.locales[0].value;
      }
    }, 200);
  },
};
</script>