export default {
  PLEASEWAIT: "कृपया प्रतीक्षा करें...",
  "ROOT:FULLSCREEN_WAIT:ALERT_MSG-TEXT|LOGOUT": "लॉगआउट हो रहे हैं..",

  "ROOT:DRAWER:TOOLBAR_GUEST_TITLE-TEXT": "स्वागत है!",
  "ROOT:DRAWER:BUTTON_HOME-TEXT": "होम",
  "ROOT:DRAWER:BUTTON_GROUPS-TEXT": "ग्रुप्स",
  "ROOT:DRAWER:BUTTON_PROFILE-TEXT": "प्रोफाइल",
  "ROOT:DRAWER:BUTTON_LOGIN-TEXT": "लॉग इन",
  "ROOT:DRAWER:BUTTON_UPDATE_EMAIL-TEXT": "ईमेल बदलें",
  "ROOT:DRAWER:BUTTON_UPDATE_PROFILE-TEXT": "अपडेट प्रोफाइल",
  "ROOT:DRAWER:BUTTON_UPDATE_PASSWORD-TEXT": "पासवर्ड बदलें",
  "ROOT:DRAWER:BUTTON_LOGOUT-TEXT": "लॉगआउट",
  "ROOT:DRAWER:BUTTON_REGISTER-TEXT": "रजिस्टर",

  VERIFYSTATUSTEXT: "अकाउंट वेरीफाई करने के लिए यहाँ क्लिक करें.",
  SIGNUPLOGINTOCONTINUE: "जारी रखने के लिए लॉगइन या साइन अप करें..",

  APP_TITLE: "विकर्ण",
  INR: "₹ %s/-",
  SAVE: "सेव करें",
  MENU: "मेनू",
  ACCOUNT: "अकाउंट",
  SETTINGS: "सेटिंग्स",
  USER_PREFS: "यूजर प्रेफरेंसेस",
  GENERAL: "सामान्य",
  DARK_THEME: "डार्क थीम",
  LIGHT_THEME: "लाइट थीम",
  ON: "ऑन",
  OFF: "ऑफ",
  SOMETHING_WENT_WRONG: "कुछ गड़बड़ हो गई!",
  NO_MORE_ITEMS: "बस! इतने ही हैं.",
  NOT_CONNECTED: "आप इन्टरनेट से नहीं जुड़े हैं.",

  Login: "लॉग इन",
  Logout: "लॉग आउट",
  Register: "रजिस्टर",
  Update_Email: "अपडेट ईमेल",
  Update_Password: "अपडेट पासवर्ड",
  Update_Profile: "अपडेट प्रोफाइल",

  WELCOME: "स्वागत है",
  HOME: "होम",
  GROUP: "ग्रुप",
  GROUPS: "ग्रुप्स",
  TEAMS: "दल",
  TEAM: "दल",
  MEMBER: "सदस्य",
  MEMBERS: "सदस्य",
  TOTAL: "कुल",
  SUBTOTAL: "कुल योग",

  GUEST: "गेस्ट",
  POINTS: "अंक",
  POINT: "अंक",

  CONFIRM_DELETE:
    "क्या आप वाकई <b style='color:red'>%s</b> को हटाना चाहते हैं?",
  YES: "जी हां",
  NO: "नहीं",
  OK: "ठीक है",
  CANCEL: "रद्द करें",
  REMOVE: "हटायें",
  UPDATE: "अपडेट",
  CREATE: "नया जोड़ें",
  NEW: "नया",
  NEXT: "अगला",
  PREVIOUS: "पिछला",
  PAGE: "पेज",
  DESCRIPTION: "विविरण",
  SUBJECT: "विषय",
  "CORRECT-OPTION": "सही ऑप्शन",
  OPTIONA: "ऑप्शन (अ)",
  OPTIONB: "ऑप्शन (ब)",
  OPTIONC: "ऑप्शन (स)",
  OPTIOND: "ऑप्शन (द)",
  TITLE: "शीर्षक",
  QUESTIONS: "प्रश्न",
  QUESTION: "प्रश्न",
  QSTN: "प्रश्न",
  TAGS: "टैग्स",
  TAG: "टैग",
  SUBJECTS: "विषय",
  SUBJECT: "विषय",
  NOTES: "नोट्स",
  NOTE: "नोट",
  MOCKTESTS: "मॉक-टेस्ट्स",
  MOCKTEST: "मॉक-टेस्ट",
  TESTS: "टेस्ट्स",
  TEST: "टेस्ट",
  SEGMENTS: "सेगमेंट्स",
  SEGMENT: "सेगमेंट",
  DURATION: "समय अवधि",
  EXPLANATION: "स्पष्टीकरण",
  NOITEMS: "उपलब्ध नहीं हैं",
  SEGMENTED: "सेगमेंट की समय अवधि का उपयोग करें?",
  ATTEMPT: "प्रयास करें",
  MARKEDFORREVIEW: "रिव्यू के लिए चिन्हित",
  START: "आरंभ करें",
  RESUME: "पुनः आरंभ करें",
  "TOTAL-QUESTIONS": "कुल प्रश्न",
  MARKANDNEXT: "मार्क और अगला",
  UNMARK: "अन-मार्क",
  CLEAR: "क्लियर",
  SUBMIT: "सबमिट",
  SAVEANDNEW: "सेव और नया",
  ACCEPTANDSTART: "स्वीकार करें और शुरू करें",
  CONFIRMLEAVE: "टेस्ट पूरा नहीं हुआ? बीच मे छोड़ कर जाना चाहते हैं?",
  USERS: "यूजर्स",
  USER: "यूजर",
  AUTH: "ऑथराइज़",
  PREPARE: "तैयारी",
  LOADING: "लोड हो रहा है...",
  SCORE: "स्कोर",
  SPEED: "स्पीड",
  ACCURACY: "सटीकता",
  CORRECT: "सही",
  INCORRECT: "गलत",
  ATTEMPTED: "प्रयास किया",
  SKIPPED: "छोड़ दिए",
  ANSWER: "उत्तर",
  RANK: "रैंक",
  "I Accept": "स्वीकार है",
  Decline: "अस्वीकार",
  DATE: "दिनांक",
  LEARN: "सीखिए",
  REPORT: "रिपोर्ट",
  MYATTEMPTS: "मेरे प्रयास",
  CONTINUEATTEMPTS: "प्रयास जारी रखें...",
  COMINGSOON: "जल्द ही उपलब्ध होगा, इंतज़ार कीजिए।",
  HELP: "सहयोग",
  AUTH_REQUIRED: "जारी रखने के लिए कृपया साइन अप या लॉग इन करें...",
  BACK: "वापस जाएं",
  EXIT: "बाहर",
  LOGIN: "लॉग इन",
  SIGNUP: "साइन अप",
  CREATEACCOUNT: "नया अकाउंट बनाएं",
  MOBILENUMBER: "मोबाईल नंबर",
  UPVOTE: "अप वोट",
  DOWNVOTE: "डाउन वोट",
  ROTATELEFT: "बाएं घुमाएं",
  ROTATERIGHT: "दायें घुमाएं",
  FLIPVERTICAL: "ऊपर/नीचे पलटें",
  FLIPHORIZONTAL: "दायें/बाएं पलटें",
  CROP: "क्रॉप",
  CHOOSEANIMAGE: "कोई इमेज चुनें",
  DONE: "हो गया",
  UPLOAD: "अपलोड",
  RESET: "रीसेट करें",
  RESTART: "फिर से शुरू करें",
  DONATE: "डोनेट",
  DONATION: "डोनेशन",
  AMOUNT: "कीमत",
  VCOINS: "vCoins",
  GCOINS: "gCoins",
  VCOIN: "vCoin",
  GCOIN: "gCoin",
  COIN: "कॉइन",
  COINS: "कॉइन्स",
  WALLET: "वॉलेट",
  MYWALLET: "मेरा वॉलेट",
  CHOOSELANGUAGE: "भाषा चुनें",
  BLOCK: "ब्लॉक",
  BLOCKED: "ब्लॉक्ड",
  UNBLOCKED: "अनब्लॉक्ड",
  UNBLOCK: "अनब्लॉक",
};
