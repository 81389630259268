import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

// function dispatchErrorAlert(error) {
//   store.dispatch("apiAlert", {
//     message:
//       error.response.data && error.response.data.message
//         ? error.response.data.message
//         : error.response.status,
//     code: error.response.status,
//   });
// }

export const fetchHeartedFeedIds = ({ commit }) => {
  return axios
    .get("reactions/hearted/feed")
    .then((res) => {
      commit("SET_HEARTED_FEED_IDS", res.data);
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const fetchHeartedFeedCommentIds = ({ commit }) => {
  return axios
    .get("reactions/hearted/feed-comments")
    .then((res) => {
      commit("SET_HEARTED_FEED_COMMENT_IDS", res.data);
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const patchHeartedFeedCommentIds = (
  { commit },
  { add = null, remove = null }
) => {
  return commit("PATCH_HEARTED_FEED_COMMENT_IDS", { add, remove });
};

export const patchHeartedFeedIds = (
  { commit },
  { add = null, remove = null }
) => {
  return commit("PATCH_HEARTED_FEED_IDS", { add, remove });
};

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};
