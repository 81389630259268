<template>
  <v-avatar size="32" contain :style="$store.getters['isThemeDark'] ? 'filter: invert(1)' : 'filter: invert(0)'">
    <v-img :style="$store.getters['getCurrLocale'] == 'hi' ? 'display:inline' : 'display:none'" src="/img/ui/translate-eng.svg" />
    <v-img :style="$store.getters['getCurrLocale'] == 'hi' ? 'display:none' : 'display:inline'" src="/img/ui/translate-hin.svg" />
  </v-avatar>
</template>

<script>
export default {
  name: 'icon-translate',
  data() {
    return {
      
    };
  },
  methods: {
    
  },
};
</script>