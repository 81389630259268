<template>
  <v-bottom-navigation
    :value="currentParentPageIndex"
    :color="color"
    grow
    :input-value="visible"
    app
  >
    <v-btn v-for="item in menu_user" :key="item.icon" @click="item.to">
      <span>{{
        isHindi ? (item.text_hi ? item.text_hi : item.text) : item.text
      }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import bus from "@/bus";
export default {
  data() {
    return {
      visible: null,
      menu_user: [
        // {
        //   icon: "mdi-account-box",
        //   text: "Profile",
        //   text_hi: "प्रोफाइल",
        //   to: () => this.routeTo("profile"),
        // },
        {
          icon: "mdi-update",
          text: "Feed",
          text_hi: "फ़ीड",
          to: () => this.routeTo("feed"),
        },
        {
          icon: "mdi-play-box",
          text: "Videos",
          text_hi: "वीडियोज़",
          to: () => this.routeTo("videos"),
        },
        {
          icon: "mdi-home",
          text: "Home",
          text_hi: "होम",
          to: () => this.routeTo("home"),
        },
        {
          icon: "mdi-book-open",
          text: "Learn",
          text_hi: "सीखिए",
          to: () => this.routeTo("learn"),
        },
        {
          icon: "mdi-clipboard-edit",
          text: "Exams",
          text_hi: "परीक्षा",
          to: () => this.routeTo("exams"),
        },
        // {
        //   icon: "mdi-head-question",
        //   text: "Doubts",
        //   text_hi: "संदेह", //
        //   to: () => this.routeTo("doubts"),
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      user: "auth/user",
    }),
    color() {
      switch (this.currentParentPage) {
        case "homexxxx":
          return "primary";
        default:
          return "primary";
      }
    },
    currentParentPageIndex() {
      switch (this.currentParentPage) {
        case "feed":
          return 0;
        case "videos":
          return 1;
        case "home":
          return 2;
        case "learn":
          return 3;
        case "exams":
          return 4;
        // case "learn":
        //   return 4;
        default:
          return undefined;
      }
    },
    currentParentPage() {
      let paths = this.$route.path.split("/");
      if (isEmpty(paths[1])) {
        return "home";
      }
      return paths[1];
    },
  },
  methods: {
    routeTo(to) {
      this.$router.push({ name: to });
    },
  },
  mounted() {
    this.visible = this.user ? true : false;
  },
  watch: {
    "$route.name"(name) {
      if (["login", "register", "forgot-password"].includes(name)) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    user(value) {
      this.visible = value ? true : false;
    },
  },
  created() {
    bus.$on("hideBottomNav", () => {
      this.visible = false;
    });
    bus.$on("showBottomNav", () => {
      this.visible = true;
    });
  },
};
</script>

<style>
</style>