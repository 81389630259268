var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.authUser.referee_uid)?_c('v-list',{staticClass:"rounded-xl"},[_c('div',{staticClass:"overline pl-5"},[_vm._v(_vm._s(_vm.$lang("youarereferredby")))]),_c('v-list-item',[_c('v-list-item-avatar',{on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile',
              params: { id: _vm.authUser.referee.id },
            })}}},[_c('v-avatar',{attrs:{"contain":"","size":"64"}},[_c('v-img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.authUser.referee.avatar}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'profile',
                params: { id: _vm.authUser.referee.id },
              })}}},[_vm._v(_vm._s(_vm.authUser.referee.display_name))]),_c('v-list-item-subtitle',[_vm._v("vikarn.com/"+_vm._s(_vm.authUser.referee.username))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                name: 'profile',
                params: { id: _vm.authUser.referee.id },
              })}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1):_c('v-card',{staticClass:"rounded-xl text-center coupon-code"},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$lang("referredbysomeone"))+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$lang("REFERREDBYSOMEONE_HINT")))]),_c('v-card-actions',[_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"block":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$lang("enterreferralcode"))+" ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$lang("referredbysomeone"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._v(_vm._s(_vm.$lang("REFERREDBYSOMEONE_HINT")))]),_c('v-card-text',[_c('v-text-field',{staticClass:"rounded-xl",attrs:{"placeholder":"Referral Code","outlined":"","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doSubmit.apply(null, arguments)}},model:{value:(_vm.referralCode),callback:function ($$v) {_vm.referralCode=$$v},expression:"referralCode"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"rounded-xl",attrs:{"x-large":"","loading":_vm.loading,"block":""},on:{"click":_vm.doSubmit}},[_vm._v(_vm._s(_vm.$lang("submit")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }