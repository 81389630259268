<template>
  <v-rating
    v-model="rating"
    color="yellow darken-3"
    background-color="grey darken-1"
    empty-icon="$ratingFull"
    half-increments
  ></v-rating>
</template>

<script>
import localforage from "@/plugins/localforage";
export default {
  data() {
    return {
      rating: 5,
    };
  },
  watch: {
    rating(val) {
      this.getRating(val);
    },
  },
  methods: {
    getRating(stars) {
      localforage
        .setItem("appRating", stars)
        .then(() => {
          return localforage.getItem("appRating");
        })
        .then((val) => {
          this.rating = parseFloat(val);
          // we got our value
        })
        .catch(function (err) {
          // we got an error
        });
    },
  },
  created() {
    this.getRating(4.5);
  },
};
</script>

<style>
</style>