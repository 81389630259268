export default [
  {
    path: "/ugcp/blocked/users",
    name: "blocked-users",
    component: () => import("../components/BlockedUsers.vue"),
    meta: {
      title: "Blocked",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
  {
    path: "/help-and-feedback",
    name: "help",
    component: () => import("../components/Feedback.vue"),
    meta: {
      title: "Help & Feedback",
      needsAuth: false,
    },
  },
  // {
  //   path: "/ugcp/blocked/videos",
  //   name: "blocked-videos",
  //   component: () => import("../components/BlockedVideos.vue"),
  //   meta: {
  //     title: "Blocked",
  //     needsAuth: true,
  //     verified: true,
  //     guest: false,
  //   },
  // },
];
