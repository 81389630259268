<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="headline orange white--text">
        Privacy Policy & Terms
      </v-card-title>

      <v-card-text class="pt-4">
        In order to use this app, you must accept
        <a href="https://vikarn.com/privacy" target="_blank">Privacy Policy</a>
        and agree to the
        <a href="https://vikarn.com/terms" target="_blank">Terms of Use</a>.
      </v-card-text>

      <v-card-text>
        इस एप्लिकेशन का उपयोग करने के लिए, आपको
        <a href="https://vikarn.com/privacy" target="_blank"> गोपनीयता नीति </a>
        को स्वीकार करना होगा एवं
        <a href="https://vikarn.com/terms" target="_blank"> उपयोग की शर्तों </a>
        को सहमति देनी होगी।
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" outlined @click.stop="handleAccept()">
          {{ $lang("I Accept") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="" text @click.stop="handleReject()">
          {{ $lang("Exit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import bus from "@/bus";
import localforage from "localforage";

import { Device } from "@capacitor/device";

import { App } from "@capacitor/app";
export default {
  name: "privacy-modal",
  data() {
    return {
      dialog: null,
    };
  },

  watch: {
    dialog(val) {
      this.$emit("opened", val);
    },
  },
  methods: {
    getStatus() {
      return localforage.getItem("isAcceptedTerms").then((val) => {
        if (!val || val != true) {
          return Promise.reject(false);
        }
        return Promise.resolve(true);
      });
    },
    setStatus(trueOrFalse) {
      return localforage.setItem("isAcceptedTerms", trueOrFalse).then(() => {
        return Promise.resolve(true);
      });
    },
    async handleAccept() {
      bus.$emit("showWait");
      // have it to localstorage
      this.setStatus(true).then(() => {
        this.dialog = false;
        bus.$emit("hideWait");
      });
    },
    async handleReject() {
      bus.$emit("showWait");

      this.setStatus(false).then(() => {});

      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform.toLowerCase() == "android") {
        App.exitApp();
      } else {
        window.location.replace("https://vikarn.com?cause=USER_DECLINED_TERMS");
      }
    },
  },
  async mounted() {
    bus.$emit("showWait");
    this.getStatus()
      .catch(() => {
        this.dialog = true;
      })
      .finally(() => {
        bus.$emit("hideWait");
      });
  },
};
</script>