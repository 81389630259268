const roles = [
  "student",
  "teacher",
  "manager",
  "moderator",
  "admin",
  "superadmin",
];
export default [
  {
    path: "/payment",
    name: "payment",
    component: () => import("../components/Payment.vue"),
    meta: {
      title: "Payment",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },

  {
    path: "/donate",
    name: "donate",
    component: () => import("../components/Donate.vue"),
    meta: {
      title: "Donate",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },

  {
    path: "/products",
    name: "products",
    component: () => import("../components/Products.vue"),
    meta: {
      title: "vCoins",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/products/:id",
    name: "product",
    component: () => import("../components/Product.vue"),
    meta: {
      title: "vCoins",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/invoice/:id",
    name: "invoice",
    component: () => import("../components/Invoice.vue"),
    meta: {
      title: "Invoice",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/payment-help/:id",
    name: "payment-help",
    component: () => import("../components/PaymentHelp.vue"),
    meta: {
      title: "PAYMENTHELP",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/my-orders",
    name: "my-orders",
    component: () => import("../components/MyOrders.vue"),
    meta: {
      title: "My Orders",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/redeem/coupon",
    name: "redeem-coupon",
    component: () => import("../components/RedeemCoupon.vue"),
    meta: {
      title: "Redeem",
      translate: true,
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/referral-program",
    name: "referral-program",
    component: () => import("../components/ReferralProgram.vue"),
    meta: {
      title: "REFERRALPROGRAM",
      translate: true,
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
];
