<template>
  <v-dialog persistent fullscreen v-model="dialog">
    <v-app-bar fixed top dark>
      <v-toolbar-title>
        {{ $lang("Update") }}
        {{ $lang("Profile") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card v-if="authUser">
      <v-card-text class="text-center justify-center">
        <v-avatar
          size="150"
          class="mt-16 mb-4 rounded-circle elevation-0 d-inline-block"
        >
          <drawer-avatar-img :showCameraIcon="true" style="cursor: pointer" />
        </v-avatar>
      </v-card-text>
      <v-divider class="mb-5"></v-divider>

      <HaveReferralCode />

      <v-divider class="my-5"></v-divider>
      <v-card-text>
        <v-text-field
          :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-LABEL')"
          :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-HINT')"
          v-model="profile.first_name"
          type="text"
          prepend-icon="mdi-account-details"
          :error-messages="errors && errors.firstname ? errors.firstname : []"
        ></v-text-field>

        <v-text-field
          :label="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-LABEL')"
          :hint="$lang('AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-HINT')"
          v-model="profile.last_name"
          type="text"
          prepend-icon="mdi-account-details"
          :error-messages="errors && errors.last_name ? errors.last_name : []"
        ></v-text-field>
        <dob-selector :profile="profile" :errors="errors" />
        <v-radio-group
          :label="$lang('gender')"
          prepend-icon="mdi-gender-male-female"
          hint="Choose a state you live in."
          v-model="profile.gender"
          :error-messages="errors && errors.gender ? errors.gender : []"
        >
          <v-radio
            v-for="itm in genders"
            :key="itm.value"
            :label="itm.text"
            :value="itm.value"
          ></v-radio>
        </v-radio-group>
        <v-autocomplete
          v-model="profile.state"
          hint="Choose an Indian state you live in."
          :items="states"
          :label="$lang('State')"
          prepend-icon="mdi-flag"
          :error-messages="errors && errors.state ? errors.state : []"
        />
        <v-text-field
          :label="$lang('City')"
          v-model="profile.city"
          type="text"
          prepend-icon="mdi-city"
          placeholder="Type your city name here"
          :error-messages="errors && errors.city ? errors.city : []"
        ></v-text-field>
        <div class="my-8">&nbsp;</div>
      </v-card-text>
    </v-card>
    <v-bottom-navigation dark bottom fixed horizontal>
      <v-btn
        @click="doUpdateProfile"
        class="
          animate__animated animate__bounce animate__delay-2s
          primary
          darken-2
        "
        dark
        block
      >
        <span>{{ $lang("Update") }} {{ $lang("Profile") }}</span>
        <v-icon>mdi-update</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";
import CountryList from "@/helpers/CountryList";
import IndianStateList from "@/helpers/IndianStateList";
import DobSelector from "./DobSelector.vue";
import HaveReferralCode from "@/app/payment/modules/HaveReferralCode";

export default {
  name: "update-profile-dialog",
  components: {
    DrawerAvatarImg,
    DobSelector,
    HaveReferralCode,
  },
  data() {
    return {
      profileHolder: {},
      profile: {},
      dialog: true,
      genders: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Other", value: "other" },
      ],
      states: IndianStateList,
      countries: CountryList,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    authUser(value) {
      this.setProfileData();
    },
  },
  mounted() {
    if (this.authUser) {
      this.setProfileData();
    }
  },
  methods: {
    setProfileData() {
      if (!this.authUser) return;
      this.profile = { ...this.authUser.profile };
    },
    ...mapActions({
      updateProfile: "auth/updateProfile",
    }),
    doUpdateProfile() {
      if (!this.profile) return;
      this.updateProfile({
        profile: this.profile,
        context: this,
      })
        .then((res) => {})
        .catch((err) => {});
    },
  },
};
</script>
