export default [
  {
    path: "/tags",
    name: "tags",
    component: () => import("../components/Tags.vue"),
    meta: {
      title: "Tags",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
  {
    path: "/tags/:id",
    name: "tags-questions",
    component: () => import("../components/TagQuestions.vue"),
    meta: {
      title: "Tags",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
