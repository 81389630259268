<template>
  <v-card class="d-flex flex-column" elevation="0">
    <v-card-text>
      <v-img src="/artwork/signup.png" />
    </v-card-text>
    <v-card-title class="display-1">{{
      $lang("Available Exams")
    }}</v-card-title>
    <v-card-text>
      <div>Teaching</div>
      <div>Bank & Insurance</div>
      <div>State & UPSC</div>
      <div>NTA UGC NET</div>
      <div>SSC & Railways Govt Job</div>
      <div>Assistant & Junior Engineer</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>