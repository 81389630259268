import store from "@/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login.vue"),
    meta: {
      title: "",
      needsAuth: false,
      verified: false,
      guest: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to, from, next) {
        return store.dispatch("auth/logout").finally(() => {
          return next({ name: "login" });
        });
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../components/Register.vue"),
    meta: {
      title: "",
      needsAuth: false,
      verified: false,
      guest: true,
    },
  },
  {
    path: "/forgot/password",
    name: "forgot-password",
    component: () => import("../components/ForgotPassword.vue"),
    meta: {
      title: "AUTH:FORGOT_PASSWORD:TITLE-TEXT",
      needsAuth: false,
      verified: false,
      guest: true,
    },
  },
  {
    path: "/forgot/password/mobile",
    name: "forgot-password-mobile",
    component: () => import("../components/ForgotPasswordMobile.vue"),
    meta: {
      title: "AUTH:FORGOT_PASSWORD:TITLE-TEXT",
      needsAuth: false,
      verified: false,
      guest: true,
    },
  },
  {
    path: "/verify/email",
    name: "verify-email",
    component: () => import("../components/VerifyEmail.vue"),
    meta: {
      title: "AUTH:VERIFY_EMAIL:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/verify/mobile",
    name: "verify-mobile",
    component: () => import("../components/VerifyMobile.vue"),
    meta: {
      title: "AUTH:VERIFY_MOBILE:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/update/email",
    name: "update-email",
    component: () => import("../components/UpdateEmail.vue"),
    meta: {
      title: "AUTH:UPDATE_EMAIL:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/update/mobile",
    name: "update-mobile",
    component: () => import("../components/UpdateMobile.vue"),
    meta: {
      title: "AUTH:UPDATE_PROFILE:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/update/password",
    name: "update-password",
    component: () => import("../components/UpdatePassword.vue"),
    meta: {
      title: "AUTH:UPDATE_PASSWORD:TITLE-TEXT",
      needsAuth: true,
      verified: false,
      guest: false,
    },
  },
  {
    path: "/update/profile",
    name: "update-profile",
    component: () => import("../components/UpdateProfile.vue"),
    meta: {
      title: "AUTH:UPDATE_PROFILE:TITLE-TEXT",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
  {
    path: "/remove/account",
    name: "remove-account",
    component: () => import("../components/RemoveAccount.vue"),
    meta: {
      title: "Remove Account",
      needsAuth: true,
      // verified: false,
      guest: false,
    },
  },
];
