import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};

export const setBookmarks = ({ commit }, items) => {
  commit("SET_BOOKMARKS", items);
};
export const toggleBookmark = ({ commit }, item) => {
  commit("TOGGLE_BOOKMARK", item);
};
