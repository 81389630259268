export default {
  TOTALCOMMENTSINCLUDINGUNAPPROVED: "Total Comments (including unapproved)",
  POSTSOMETHING: "Post something...",
  SUBMITAPOST: "Submit a Post",
  SUBMITACOMMENT: "Submit a Comment",
  NOCOMMENTS: "Be the first to comment.",
  COMMENTS: "Comments",
  COMMENT: "Comment",
  REACTIONS: "Reactions",
  REACTION: "Reaction",
  FEEDS: "Feeds",
  FEED: "Feed",
  FEEDPOSTS: "Feed Posts",
};
