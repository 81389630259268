export default [
  {
    path: "/profile/:id?",
    name: "profile",
    component: () => import("../components/Profile/index.vue"),
    meta: {
      title: "PROFILE",
      needsAuth: true,
      verified: true,
      guest: false,
    },
  },
];
