const roles = [
  "admin",
  "superadmin",
  "manager",
  "moderator",
  "teacher",
  "parent",
  "student",
  "member",
];
export default [
  {
    path: "/feed",
    name: "feed",
    component: () => import("../components/Feed"),
    meta: {
      // title: "Profile",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
  {
    path: "/feed/:id",
    name: "feed-post",
    component: () => import("../components/FeedPost"),
    meta: {
      // title: "DOUBT",
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
