<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormattedMomentjs"
        label="Birthday date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicker"
      :max="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      "
      min="1950-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
// import Datepicker from "vuejs-datepicker";

export default {
  props: ["profile", "errors"],
  components: {},
  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date(val) {
      this.profile.dob = this.computedDateFormattedMomentjs;
    },
    "profile.dob"(val) {
      this.date = val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD-MMM-YYYY") : "";
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.profile.dob = this.computedDateFormattedMomentjs;
    },
  },
  mounted() {
    if (this.profile && this.profile.dob) {
      // this.date = this.profile.dob.toString(); //new Date(this.profile.dob).toString();
    }
  },
};
</script>