import Vue from "vue";
import EasyTranslator from "./translator";
import { locales as appLocales } from "@/app/index";
import { locales as gamesLocales } from "@/games/index";
import store from "../store";
import localforage from "@/plugins/localforage";

import en from "./en";
import hi from "./hi";

localforage.getItem("currLocale").then((val) => {
  store.dispatch("setCurrLocale", val);
});

export const getLocale = () => {
  let locale = store.getters.getCurrLocale;
  return locale;
};
export const setLocale = (locale) => {
  store.dispatch("setCurrLocale", locale);
};

let options = {
  locale: getLocale(),
  fallbackLocale: "en",
  locales: {
    en: {
      ...appLocales.en,
      ...gamesLocales.en,
      ...en,
    },
    hi: {
      ...appLocales.hi,
      ...gamesLocales.hi,
      ...hi,
    },
  },
};

export const locales = Vue.use(EasyTranslator, options);

Vue.use(EasyTranslator, options);
