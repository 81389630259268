<template>
  <span></span>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";

import { Device } from "@capacitor/device";
import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { Browser } from "@capacitor/browser";

export default {
  name: "fcm-push-notification",
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    async fcmInit() {
      const info = await Device.getInfo();
      if (info.platform != "android") {
        return;
      }

      // external required step
      // register for push
      await PushNotifications.requestPermissions();
      await PushNotifications.register().then(() =>
        // this.$store.dispatch("setAlert", {
        //   message:
        //     "You may receive push notifications, you can turn these off from settings.",
        //   type: "orange darken-3 white--text",
        // })
        console.log("PushNotifications registered")
      );

      this.subscribeTo({ topic: "doubts" });
      this.subscribeTo({ topic: "promotions" });
      this.subscribeTo({ topic: "quotes" });
      this.subscribeTo({ topic: "social" });
      this.subscribeTo({ topic: "alert" });
      this.subscribeTo({ topic: "jobs" });
      this.subscribeTo({ topic: "comments" });

      PushNotifications.addListener("registration", (token) => {
        console.log("Push registration success, token: " + token.value);
        // alert("Push registration success, token: " + token.value);
        this.attachTokenWithUser(token.value);
      });

      // this listener will run if app is in foreground i.e open.
      PushNotifications.addListener("pushNotificationReceived", (noti) => {
        console.log("pushNotificationReceived", noti);
        let msg = noti.title
          ? `<b>${noti.title}</b><br/>${noti.body}`
          : noti.body;

        this.$store.dispatch("auth/fetchUser");

        this.$store.dispatch("setAlert", {
          type:
            noti.data && noti.data.type
              ? noti.data.type
              : "primary white--text",
          message: msg,
          icon: noti.data && noti.data.icon ? noti.data.icon : null,
          duration: noti.data && noti.data.duration ? noti.data.duration : 5000,
          isHTML: noti.title ? true : false,
          uri: noti.data && noti.data.uri ? noti.data.uri : null,
          url: noti.data && noti.data.url ? noti.data.url : null,
          prominent:
            noti.data && noti.data.prominent && noti.data.prominent == "true"
              ? true
              : false,
        });
      });

      // this listener will run if app is in backround i.e closed.
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          if (
            notification &&
            notification.notification &&
            notification.notification.data
          ) {
            let data = notification.notification.data;
            data.uri = data.uri ? data.uri : "/";

            this.$emit("received", data);
            if (data && data.url && data.url.length > 0) {
              this.goToUrl(data.url);
            } else {
              this.$router.push({ path: data.uri });
            }
          }
        }
      );

      // Enable the auto initialization of the library
      FCM.setAutoInit({ enabled: true }).then(() =>
        console.log(`Auto init enabled`)
      );

      // Check the auto initialization status
      FCM.isAutoInitEnabled().then((r) => {
        console.log("Auto init is " + (r.enabled ? "enabled" : "disabled"));
      });
    },
    goToUrl(url) {
      bus.$emit("showWait");

      Browser.open({ url: url });

      setTimeout(() => {
        bus.$emit("hideWait");
      }, 2000);
    },
    async attachTokenWithUser(token) {
      const info = await Device.getInfo();
      if (info.platform != "android") {
        return;
      }
      return axios
        .post(`/fcm/attach/${token}`, {
          device: info,
        })
        .then(() => {
          console.log("token attached", token);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subscribeTo({ topic = "TOPIC" }) {
      // now you can subscribe to a specific topic
      FCM.subscribeTo({ topic })
        .then((r) => console.log(`subscribed to ${topic}`))
        .catch((err) => console.log(err));
    },

    unsubscribeFrom({ topic = "TOPIC" }) {
      // Unsubscribe from a specific topic
      FCM.unsubscribeFrom({ topic })
        .then(() => console.log(`unsubscribed from ${topic}`))
        .catch((err) => console.log(err));
    },

    getFCMToken() {
      // Get FCM token instead the APN one returned by Capacitor
      return FCM.getToken()
        .then((res) => {
          console.log("fcm token", res.token);
          return Promise.resolve(res.token);
        })
        .catch((err) => {
          console.error("fcm err", err);
          return Promise.reject(err);
        });
    },

    deleteToken() {
      // Remove FCM instance
      return FCM.deleteInstance()
        .then((res) => {
          console.log("fcm deleteInstance", res.token);
          return Promise.resolve(res.token);
        })
        .catch((err) => {
          console.error("fcm deleteInstance err", err);
          return Promise.reject(err);
        });
    },
  },
  watch: {
    authUser(val) {
      if (val && val.id) {
        this.fcmInit();
      }
    },
  },
  mounted() {},
};
</script>
