<template>
  <v-card class="d-flex flex-column" elevation="0">
    <v-card-text>
      <v-img src="/app-logo/vikarn-black-green.png" />
    </v-card-text>
    <v-card-text class="headline">
      A unique and free sarkari exam preparation companion app.
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>