<template>
  <div class="px-1 pt-3">
    <v-card
      v-for="(item, i) in items"
      :key="i"
      @click="$emit('clicked', item)"
      outlined
      :class="`mb-2 rounded-lg`"
    >
      <v-img
        v-if="item.image_url"
        class="ma-1 rounded-lg"
        :src="item.image_url"
      >
      </v-img>
      <div class="px-2 pb-2 pt-1">
        <div class="caption d-flex flex-row">
          <v-badge
            fixed
            left
            top
            v-if="!item.viewed"
            dot
            color="orange"
            offset-y="14"
            offset-x="8"
            class="anim-rotate-x"
          />
          <span :class="!item.viewed ? `ml-3` : ``">{{
            item.created_at | fromNow
          }}</span>
        </div>
        <div class="subtitle">
          {{ item.title ? item.title : item.body }}
        </div>
        <div class="body-2" v-if="item.title">
          {{ item.body }}
        </div>
      </div>
      <v-progress-linear
        height="3"
        :color="item.data && item.data.type ? item.data.type : 'grey'"
        :value="100"
      />
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["items"],
  components: {},
};
</script>

<style>
</style>