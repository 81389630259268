export default [
  {
    path: "/notes",
    name: "notes",
    component: () => import("../components/Notes.vue"),
    meta: {
      title: "NOTES",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
  {
    path: "/notes/:id",
    name: "note",
    component: () => import("../components/Note.vue"),
    meta: {
      title: "NOTE",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
