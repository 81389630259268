export default {
  DOUBTS: "संदेह",
  DOUBT: "संदेह",
  ASKAQUESTION: "तैयारी से जुड़े प्रश्न, हमसे पूछिए...",
  SUBMITADOUBT: "डाउट सबमिट कीजिए...",
  SUBMITANANSWER: "उत्तर सबमिट करें",
  NOANSWERS: "अभी तक कोई उत्तर नहीं है, अपना उत्तर सबमिट करें।",
  TYPESOMETHING: "कुछ टाइप कीजिए...",
  WRITESOMETHING: "कुछ लिखिए...",
  PENDINGAPPROVAL: "अनुमोदन के लिए लंबित",
  APPROVED: "अनुमोदित",
  APPROVE: "अनुमोदन करें",
  ACCEPTED: "स्वीकृत",
  ACCEPT: "स्वीकार करें",
  TOTALANSWERSINCLUDINGUNAPPROVED: "कुल उत्तर संख्या (अस्वीकृत सहित)",
  DOUBTANSWERS: "संदेह के उत्तर",
};
