export default {
  TOTALCOMMENTSINCLUDINGUNAPPROVED: "कुल कमेंट्स (अस्वीकृत सहित)",
  POSTSOMETHING: "कुछ पोस्ट कीजिए...",
  SUBMITAPOST: "पोस्ट सबमिट कीजिए.",
  SUBMITACOMMENT: "नया कमेन्ट जोड़िए",
  NOCOMMENTS: "कमेन्ट करने वाले पहले व्यक्ति बनिए।",
  COMMENTS: "कमेंट्स",
  COMMENT: "कमेन्ट",
  REACTIONS: "रिएक्शन",
  REACTION: "रिएक्शन्स",
  FEEDS: "फ़ीडस्",
  FEED: "फ़ीड",
  FEEDPOSTS: "फ़ीड पोस्ट्स",
};
