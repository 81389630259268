import { axios } from "@/plugins/axios";
import store from "@/store";

let rzp1 = null;
export const getRazorpayKey = () => {
  return axios
    .get("globals/razorpay")
    .then((res) => {
      return Promise.resolve(res.data.key_id);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const generateOrder = (data) => {
  return axios
    .post("payments/order", data)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const handleClick = ({ amount, user }) => {
  return create({ amount, user })
    .then(() => {
      if (rzp1) {
        rzp1.open();
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const create = ({ amount, user }) => {
  let title = "Donation of INR " + amount / 100 + "/- from user: #" + user.id;
  return generateOrder({ amount, title })
    .then((data) => {
      console.log("data", data);
      return getRazorpayKey().then((key_id) => {
        return initiatePayment(data, user, key_id);
      });
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const handlePaymentFailed = (res) => {
  console.log("error", res);
  alert("payment failed" + res.error.description);
};

const handlePaymentSuccess = (data) => {
  // submit to server to be saved in database
  console.log("data", data);
  return axios
    .post("payments/validate/payment", data)
    .then((res) => {
      console.log("payment validation", res.data);
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
};

export const initiatePayment = (
  { provider_order_id, amount },
  user,
  key_id
) => {
  console.log("provider_order_id pre", provider_order_id);
  var options = {
    key: key_id, // Enter the Key ID generated from the Dashboard
    amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: store.getters.getCurrLocale == "hi" ? "विकर्ण" : "Vikarn",
    description:
      store.getters.getCurrLocale == "hi"
        ? "सहयोग देने के लिए दान।"
        : "Donation to support.",
    image: "https://vikarn.com/assets/imgs/logo-vikarn.com.png",
    order_id: provider_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: (res) => handlePaymentSuccess(res),
    prefill: {
      name: user.username,
      email: user.email,
      contact: user.mobile,
    },
    notes: {
      address: "Vikarn Corp",
    },
    theme: {
      color: "#3399cc",
    },
  };

  rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", handlePaymentFailed);
};
