import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const vuetify = new Vuetify({
  iconfont: "mdi",
  theme: { dark: true },
});

export default vuetify;
