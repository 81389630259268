<template >
  <div class="d-flex flex-row">
    <!-- <ThemeToggleBtn /> -->
    <locale-toggle-btn />
    <share-btn
      class="animate__animated animate__flip animate__delay-5s animate__slow"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LocaleToggleBtn from "./LocaleToggleBtn.vue";

import ShareBtn from "./ShareBtn";
// import ThemeToggleBtn from "./ThemeToggleBtn";
export default {
  name: "context-button",
  components: {
    LocaleToggleBtn,
    // ThemeToggleBtn,
    ShareBtn,
  },
  data() {
    return {
      showSettings: false,
      to: null,
      icon: "mdi-gear",
      title: null,
    };
  },
  watch: {},
  created() {},
  methods: {},
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
};
</script>