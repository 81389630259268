import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};
