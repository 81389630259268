<template>
  <v-dialog v-model="dialog" fullscreen>
    <div>
      <v-tabs-items v-model="tab" class="pa-0 ma-0">
        <v-row no-gutters class="justify-center">
          <v-col cols="12" md="4">
            <v-tab-item style="height: 100vh">
              <tab-language
                class="
                  d-flex
                  justify-center
                  align-center
                  text-center
                  fill-height
                "
              />
            </v-tab-item>
            <v-tab-item style="height: 100vh">
              <tab-one
                class="
                  d-flex
                  justify-center
                  align-center
                  text-center
                  fill-height
                "
              />
            </v-tab-item>
            <v-tab-item style="height: 100vh">
              <tab-two
                class="
                  d-flex
                  justify-center
                  align-center
                  text-center
                  fill-height
                "
              />
            </v-tab-item>
            <v-tab-item style="height: 100vh">
              <tab-three
                class="
                  d-flex
                  justify-center
                  align-center
                  text-center
                  fill-height
                "
              />
            </v-tab-item>
          </v-col>
        </v-row>
      </v-tabs-items>
      <v-btn
        v-if="tab == 3"
        @click.stop="handleDone"
        class="green darken-2 white--text"
        fab
        fixed
        bottom
        right
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn v-if="tab < 3" @click.stop="handleNext" fab fixed bottom right>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn v-if="tab > 0" @click.stop="handlePrevious" fab fixed bottom left>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import Storage from "@/plugins/localforage";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import TabLanguage from "./TabLanguage";
export default {
  name: "setup",
  components: {
    TabOne,
    TabTwo,
    TabThree,
    TabLanguage,
  },
  data() {
    return {
      dialog: null,
      tab: 0,
    };
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {
    async handleDone() {
      await Storage.setItem("setupDone", true);
      this.dialog = false;
      this.$router.replace({ name: "register" });
    },
    async handleNext() {
      if (this.tab >= 4) {
        this.tab = 0;
      } else {
        this.tab++;
      }
    },
    async handlePrevious() {
      if (this.tab < 1) {
        this.tab = 4;
      } else {
        this.tab--;
      }
    },
  },
  mounted() {
    Storage.getItem("setupDone").then((value) => {
      if (!value) {
        this.dialog = true;
      }
    });
  },
};
</script>

<style>
</style>