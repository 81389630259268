<template>
  <v-btn v-tooltip="$lang('Share')" icon @click.stop="share()">
    <v-icon>mdi-share-variant-outline</v-icon>
  </v-btn>
</template>

<script>
import { Share } from "@capacitor/share";
export default {
  methods: {
    async share() {
      console.log("this.$route.path", this.$route);
      await Share.share({
        title: "आपकी परीक्षा की तैयारी के लिए सब कुछ।",
        text: "ऑनलाइन पाठ्यक्रम, प्रैक्टिस क्वेश्चन बैंक, मॉक टेस्ट सीरीज़, स्टडी नोट्स, रणनीति और तैयारी योजना, मार्गदर्शन, सलाह और बहुत कुछ।",
        url: "https://app.vikarn.com" + this.$route.path,
        dialogTitle: "Share with firends",
      });
    },
  },
};
</script>

<style>
</style>