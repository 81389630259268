export default {
  DOUBTS: "Doubts",
  DOUBT: "Doubt",
  ASKAQUESTION: "Ask a question",
  SUBMITADOUBT: "Submit a Doubt",
  SUBMITANANSWER: "Submit an answer",
  NOANSWERS: "No answers yet, Submit yours.",
  TYPESOMETHING: "Type something...",
  WRITESOMETHING: "Write something...",
  PENDINGAPPROVAL: "Pending for Approval",
  APPROVED: "Approved",
  APPROVE: "Approve",
  ACCEPTED: "Accepted",
  ACCEPT: "Accept",
  TOTALANSWERSINCLUDINGUNAPPROVED: "Total Answers (including unapproved)",
  DOUBTANSWERS: "Doubt Answers",
};
