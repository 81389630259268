<template>
  <v-navigation-drawer
    v-if="authUser"
    v-model="drawer"
    right
    fixed
    temporary
    src=""
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>mdi-bell</v-icon>
      </v-list-item-avatar>

      <v-list-item-content @click="$router.push({ name: 'notifications' })">
        <v-list-item-title>{{ $lang("Notifications") }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          :loading="loading"
          @click="fetchItems"
          :color="
            authUser && authUser.__meta__.notifications_count > 0
              ? 'red'
              : 'green'
          "
          small
          dark
          class="rounded-xl"
        >
          {{ authUser.__meta__.notifications_count }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-divider></v-divider>
    <div
      v-if="items && items.length > 0"
      dense
      style="height: calc(100vh - 60px); overflow-y: scroll"
    >
      <NotificationsList :items="items" @clicked="handleClick" />
    </div>
    <div
      v-else
      class="contain justify-center align-center caption d-flex flex-column"
      style="height: calc(100vh - 60px); overflow-y: scroll"
    >
      <v-spacer></v-spacer>
      <v-img height="0" src="/artwork/notification.png" />
      <span>{{ $lang("noitems") }}</span>
      <v-spacer></v-spacer>
    </div>
    <v-divider></v-divider>
    <v-btn
      @click="handleClear"
      v-tooltip="`Clear read notifications`"
      fab
      fixed
      bottom
      right
    >
      <v-icon>mdi-notification-clear-all</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import NotificationsList from "../components/NotificationsList.vue";
import { Browser } from "@capacitor/browser";

export default {
  name: "app-drawer-right",
  props: ["show"],
  components: { NotificationsList },
  data() {
    return {
      loading: false,
      drawer: null,
      notificationCount: 1,
      items: [
        {
          body: "Congratulations! You've just save a lot of time.",
          icon: "mdi-clock",
          to: () => {},
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    show(val) {
      this.drawer = this.show;
    },
    drawer(val) {
      this.$emit("drawer", val);
      if (val) this.fetchItems();
    },
  },
  created() {
    this.drawer = this.show;
  },
  methods: {
    openUrl(url) {
      Browser.open({ url: url });
    },
    handleClear() {
      if (this.loading) return;
      this.loading = true;
      return axios
        .get("notifications/mark/archived")
        .then((res) => {
          return this.$store.dispatch("auth/fetchUser");
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
            this.fetchItems();
          }, 1000);
        });
    },
    handleClick(item) {
      if (this.loading) return;
      this.loading = true;
      return axios
        .get("notifications/viewed/" + item.id)
        .then((res) => {
          item.viewed = 1;
          if (this.authUser && this.authUser.__meta__) {
            this.authUser.__meta__.notifications_count--;
          }
        })
        .finally(() => {
          this.loading = false;
          if (item.data && item.data.uri) {
            this.$router.push(item.data.uri);
          } else if (item.data && item.data.url) {
            this.openUrl(item.data.url);
          } else {
            this.$router.push({
              name: "notification",
              params: { id: item.id },
              query: { parent_page: this.$route.query.page },
            });
          }
        });
    },
    fetchItems() {
      if (this.loading) return;
      this.loading = true;
      return axios
        .get("notifications?page=1&perPage=50&orderby=viewed|asc")
        .then((res) => {
          this.items = res.data.data;
          // optimize this, maybe delete from auth store witout fetch
          // this.$store.dispatch("auth/fetchUser");
          // set notification count
          let unread = this.items.filter((el) => !el.viewed).length;
          if (this.authUser && this.authUser.__meta__) {
            this.authUser.__meta__.notifications_count = unread;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style>
</style>