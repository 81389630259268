export default {
  PAYMENTS: "पेमेंट्स",
  PAYMENT: "पेमेंट",
  BROWSESTORE: "स्टोर ब्राउज़ करें",
  PURCHASE: "खरीदिए",
  PAYMENTHELP: "पेमेंट हेल्प",
  ORDERSTATUS: "ऑर्डर स्टेटस",
  REDEEMCOUPON: "कूपन रीडीम कराएं",
  REDEEM: "रीडीम",
  COUPON: "कूपन",
  ENTERCOUPONCODE: "कूपन कोड भरिए",
  REFERRALPROGRAM: "रेफरल प्रोग्राम",
  COINSCREDITEDYOURWALLET: "आपके वॉलेट मे %s कोइन्स जमा कर दिए गए हैं।",
  YOUGETCOINS: "इस कूपन से आपको %s कोइन्स  मिलेंगे।",
  REFERREDBYSOMEONE: "किसी ने आपको रेफर किया है?",
  REFERREDBYSOMEONE_HINT:
    "यदि किसी ने आपको रेफर किया है, तो आप नीचे रेफ़रल कोड दर्ज कर सकते हैं, आप दोनों को एक-एक कूपन मिलेगा।",
  YOUAREREFERREDBY: "आपको रेफर किया है...",
  YOURREFERRALCODE: "आपका रेफरल कोड",
  REFERFRIENDSEARNCOINS: "दोस्तों को रेफर करें, कोइन्स कमाएं।",
  ENTERREFERRALCODE: "रेफरल कोड दर्ज कीजिए",
};
