<template>
  <v-img
    :src="imagePreview ? imagePreview : authUser.avatar"
    :alt="authUser.display_name"
    @click="openUpload"
    v-if="authUser && authUser.avatar"
  >
    <form enctype="multipart/form-data" @submit.prevent="doUpload()" class="">
      <input
        name="file"
        type="file"
        :id="inputIdent"
        @change="updatePreview"
        style="display: none"
      />
    </form>
    <v-icon
      v-if="showCameraIcon"
      color="white"
      class="animate__animated animate__bounce animate__delay-3s"
      style="opacity: 0.5; margin-top: 0.9em"
      >mdi-camera</v-icon
    >
  </v-img>
  <v-icon v-else large @click="openUpload">mdi-account</v-icon>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "drawer-avatar-img",
  props: ["inputId", "showCameraIcon"],
  data() {
    return {
      imagePreview: null,
      rndm: Math.round(Math.random() * 1000),
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    inputIdent() {
      return "file-" + (this.inputId ? this.inputId : this.rndm);
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      updateAvatar: "auth/updateAvatar",
    }),
    openUpload() {
      document.getElementById(this.inputIdent).click();
    },
    updatePreview(e) {
      var reader,
        files = e.target.files;
      if (files.length === 0) {
        console.log("Empty");
      }
      reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      if (files[0]) reader.readAsDataURL(files[0]);
      this.doUpload();
    },

    doUpload() {
      var file = document.querySelector("#" + this.inputIdent);
      this.updateAvatar({ file: file, context: this });
    },
  },
};
</script>
<style scoped>
img {
  -webkit-transition: -webkit-transform 1s; /* Safari */
  transition: transform 1s;
}

img:hover {
  -webkit-transform: rotate(360deg); /* Safari */
  transform: rotate(360deg);
  cursor: pointer;
}
</style>
