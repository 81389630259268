import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";

export const resetState = ({ commit }) => {
  commit("RESET_STATE", null);
};

export const setScore = ({ commit }, score) => {
  commit("SET_SCORE", score);
};
