export default {
  "REMOVE-ACCOUNT": "अकाउंट हटायें",
  "AUTH:PASSWORD_AND_PASSWORD_CONFIRM_DONT_MATCH":
    "पासवर्ड और पासवर्ड कन्फर्म मेल नहीं खाते.",
  "AUTH:PASSWORD_MISSING": "पासवर्ड फील्ड खली है.",

  "AUTH:LOGIN:TITLE-TEXT": "लॉग इन",
  "AUTH:LOGIN:CARD_TITLE-TEXT": "जारी रखने के लिए लॉग इन कीजिये.",
  "AUTH:LOGIN:INPUT_TEXT_EMAIL-LABEL": "ईमेल एड्रेस",
  "AUTH:LOGIN:INPUT_TEXT_EMAIL-HINT": "मान्य और एक्टिव ईमेल की ज़रूरत होगी.",
  "AUTH:LOGIN:INPUT_TEXT_PASSWORD-LABEL": "पासवर्ड",
  "AUTH:LOGIN:INPUT_TEXT_PASSWORD-HINT": "कम से कम 6 अक्षर का होना चाहिए.",
  "AUTH:LOGIN:BUTTON_FORGOT_PASSWORD-TEXT": "पासवर्ड भूल गए?",
  "AUTH:LOGIN:BUTTON_SUBMIT-TEXT": "लॉग इन",
  "AUTH:LOGIN:BUTTON_REGISTER-TEXT": "नया अकाउंट बनायें",

  "AUTH:FORGOT_PASSWORD:TITLE-TEXT": "पासवर्ड भूल गए",
  "AUTH:FORGOT_PASSWORD:CARD_TITLE-TEXT": "पासवर्ड रीसेट करें",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-LABEL": "ईमेल एड्रेस",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_EMAIL-HINT":
    "वैलिड ईमेल एड्रेस की ज़रूरत होगी.",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-LABEL": "रीसेट कोड",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_CODE-HINT":
    "रीसेट कोड पाने के लिए अपना ईमेल चेक करें.",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-LABEL": "पासवर्ड",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD-HINT":
    "कम से कम 6 अक्षर का होना चाहिए.",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-LABEL": "पासवर्ड कन्फर्म",
  "AUTH:FORGOT_PASSWORD:INPUT_TEXT_PASSWORD_CONFIRM-HINT":
    "पासवर्ड कन्फर्म और पासवर्ड सामान होना चाहिए.",
  "AUTH:FORGOT_PASSWORD:BUTTON_SUBMIT-TEXT": "कोड भेजें",
  "AUTH:FORGOT_PASSWORD:BUTTON_SEND_AGAIN-TEXT": "फिर से भेजें",
  "AUTH:FORGOT_PASSWORD:BUTTON_VERIFY-TEXT": "वेरीफाई",
  "AUTH:FORGOT_PASSWORD:BUTTON_UPDATE-TEXT": "पासवर्ड बदलें",
  "AUTH:FORGOT_PASSWORD:BUTTON_REGISTER-TEXT": "नया अकाउंट बनायें",
  "AUTH:FORGOT_PASSWORD:BUTTON_LOGIN-TEXT": "लॉग इन",

  "AUTH:REGISTER:TITLE-TEXT": "रजिस्टर",
  "AUTH:REGISTER:INPUT_TEXT_FIRSTNAME-LABEL": "प्रथम नाम",
  "AUTH:REGISTER:INPUT_TEXT_FIRSTNAME-HINT": "कम से कम 2 अक्षर का होना चाहिए.",
  "AUTH:REGISTER:INPUT_TEXT_USERNAME-LABEL": "यूजरनेम",
  "AUTH:REGISTER:INPUT_TEXT_USERNAME-HINT": "कम से कम 4 अक्षर का होना चाहिए.",
  "AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL": "ईमेल एड्रेस",
  "AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT": "मान्य और एक्टिव ईमेल की ज़रूरत होगी.",
  "AUTH:REGISTER:INPUT_TEXT_PASSWORD-LABEL": "पासवर्ड",
  "AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT": "कम से कम 6 अक्षर का होना चाहिए.",
  "AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-LABEL": "पासवर्ड कन्फर्म",
  "AUTH:REGISTER:INPUT_TEXT_PASSWORD_CONFIRM-HINT":
    "पासवर्ड कन्फर्म और पासवर्ड सामान होना चाहिए.",
  "AUTH:REGISTER:CARD_TITLE-TEXT": "नया अकाउंट बनायें...",
  "AUTH:REGISTER:BUTTON_SUBMIT-TEXT": "रजिस्टर",
  "AUTH:REGISTER:BUTTON_LOGIN-TEXT": "पहले से अकाउंट है?",

  "AUTH:VERIFY_EMAIL:TITLE-TEXT": "वेरीफाई ईमेल",
  "AUTH:VERIFY_EMAIL_TITLE": "अपना ईमेल वेरीफाई करें..",
  "AUTH:VERIFY_EMAIL_HINT":
    "हमने आपके दिए हुए ईमेल अड्रेस पर एक कोड भेजा है, उस कोड को यहाँ नीचे दिए गए बॉक्स मे डालें...",
  "AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-LABEL": "वेरिफिकेशन कोड",
  "AUTH:VERIFY_EMAIL:INPUT_TEXT_SALT-HINT":
    "कोड पाने के लिए अपना ईमेल चेक करें.",
  "AUTH:VERIFY_EMAIL:BUTTON_SUBMIT-TEXT": "वेरीफाई",
  "AUTH:VERIFY_EMAIL:BUTTON_SEND_AGAIN-TEXT": "कोड नहीं मिला?",

  "AUTH:VERIFY_MOBILE:TITLE-TEXT": "वेरीफाई मोबाईल",
  "AUTH:VERIFY_MOBILE_TITLE": "अपना मोबाईल वेरीफाई करें..",
  "AUTH:VERIFY_MOBILE_HINT":
    "हमने आपके दिए हुए मोबाईल नंबर पर एक ओटीपी कोड भेजा है, उस कोड को यहाँ नीचे दिए गए बॉक्स मे डालें...",
  "AUTH:VERIFY_MOBILE:INPUT_TEXT_SALT-LABEL": "वेरिफिकेशन कोड",
  "AUTH:VERIFY_MOBILE:INPUT_TEXT_SALT-HINT":
    "कोड पाने के लिए अपना मोबाईल चेक करें.",
  "AUTH:VERIFY_MOBILE:BUTTON_SUBMIT-TEXT": "वेरीफाई",
  "AUTH:VERIFY_MOBILE:BUTTON_SEND_AGAIN-TEXT": "कोड नहीं मिला?",

  "AUTH:UPDATE_EMAIL:TITLE-TEXT": "ईमेल अपडेट",
  "AUTH:UPDATE_EMAIL:CARD_TITLE-TEXT": "अपना ईमेल बदलें..",
  "AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-LABEL": "नया ईमेल एड्रेस",
  "AUTH:UPDATE_EMAIL:INPUT_TEXT_EMAIL-HINT": "मान्य ईमेल की ज़रूरत होगी.",
  "AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-LABEL": "मौजूदा पासवर्ड",
  "AUTH:UPDATE_EMAIL:INPUT_TEXT_PASSWORD-HINT": "अपना मौजूदा पासवर्ड डालें.",
  "AUTH:UPDATE_EMAIL:BUTTON_SUBMIT-TEXT": "ईमेल बदलें",
  "AUTH:UPDATE_EMAIL:BUTTON_SECONDARY-TEXT": "बाद में बदलेंगे?",

  "AUTH:UPDATE_PASSWORD:TITLE-TEXT": "पासवर्ड अपडेट",
  "AUTH:UPDATE_PASSWORD:CARD_TITLE-TEXT": "अपना पासवर्ड बदलें..",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD-LABEL": "मौजूदा पासवर्ड",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD-HINT": "अपना मौजूदा पासवर्ड डालें.",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW-LABEL": "नया पासवर्ड",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW-HINT":
    "कम से कम 6 अक्षर का होना चाहिए.",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW_CONFIRM-LABEL":
    "नया पासवर्ड कन्फर्म",
  "AUTH:UPDATE_PASSWORD:INPUT_TEXT_PASSWORD_NEW_CONFIRM-HINT":
    "नए पासवर्ड से मेल खाना चाहिए.",
  "AUTH:UPDATE_PASSWORD:BUTTON_SUBMIT-TEXT": "पासवर्ड बदलें",
  "AUTH:UPDATE_PASSWORD:BUTTON_SECONDARY-TEXT": "बाद में बदलेंगे?",

  "AUTH:UPDATE_PROFILE:TITLE-TEXT": "प्रोफाइल अपडेट",
  "AUTH:UPDATE_PROFILE:CARD_TITLE-TEXT": "अपनी जानकारी अपडेट करें..",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-LABEL": "प्रथम नाम",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_FIRSTNAME-HINT":
    "कम से कम 2 अक्षर का होना चाहिए.",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-LABEL": "मध्य नाम",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_MIDDLENAME-HINT":
    "कम से कम 2 अक्षर का होना चाहिए.",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-LABEL": "उप नाम",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_LASTNAME-HINT":
    "कम से कम 2 अक्षर का होना चाहिए.",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-LABEL": "मौजूदा पासवर्ड",
  "AUTH:UPDATE_PROFILE:INPUT_TEXT_PASSWORD-HINT": "अपना मौजूदा पासवर्ड डालें.",
  "AUTH:UPDATE_PROFILE:BUTTON_SUBMIT-TEXT": "अपडेट",
  "AUTH:UPDATE_PROFILE:BUTTON_SECONDARY-TEXT": "बाद में करेंगे?",

  RESENDCODEWAITMSG: "आप %s सेकंड के बाद ओटीपी दोबारा भेज सकते हैं.",
  RESENDCODE: "दोबारा वेरीफिकेशन कोड भेजें",
  SIGNUPWITHEMAIL: "ईमेल से साइन अप",
  SIGNUPWITHMOBILE: "मोबाईल से साइन अप",
  ORSIGNUPWITH: "या नीचे दिए विकल्प से साइन अप",
  ALREADYHAVEANACCOUNT: "पहले से अकाउंट है?",
  LOGINWITHEMAIL: "ईमेल से लॉगइन",
  LOGINWITHMOBILE: "मोबाईल नंबर से लॉगइन",
  ORLOGINWITH: "या नीचे दिए विकल्प से लॉगइन",
  DONTHAVEANACCOUNT: "अकाउंट नहीं है?",
  MOBILENUMBERRULE: "मोबाईल नंबर 10 डिजिट का होना चाहिए।",
  PICKANOPTION: "नीचे दिए गए किसी भी विकल्प को चुनें",
  SIGNINWITH: "के द्वारा साइन इन करें",
  SIGNINGOOGLE: "गूगल द्वारा साइन इन करें",
  SIGNINMOBILE: "मोबाईल द्वारा साइन इन करें",
  SIGNINEMAIL: "ईमेल द्वारा साइन इन करें",
  SIGNINTRUECALLER: "ट्रू-कॉलर द्वारा साइन इन करें",
  SIGNUPWITH: "के द्वारा साइन अप करें",
  SIGNUPGOOGLE: "गूगल द्वारा साइन अप करें",
  SIGNUPMOBILE: "मोबाईल द्वारा साइन अप करें",
  SIGNUPEMAIL: "ईमेल द्वारा साइन अप करें",
  SIGNUPTRUECALLER: "ट्रू-कॉलर द्वारा साइन अप करें",
  SENDOTP: "ओटीपी भेजें",
  CHANGEPASSWORD: "पासवर्ड बदलें",
  OTPNOTRECEIVED: "ओटीपी नहीं मिला?",
  GOTOTP: "ओटीपी मिला?",
  FORGOTPASSWORD: "पासवर्ड भूल गए?",
  MOBILE: "मोबाईल",
  ADDMOBILE: "मोबाईल जोड़ें",
  REMOVEMOBILE: "मोबाईल हटायें",
  UPDATEMOBILE: "मोबाईल बदलें",
  CURRENT: "मौजूदा",
  DOB: "जन्म तिथि",
};
