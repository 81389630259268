export default [
  {
    path: "/videos",
    name: "videos",
    component: () => import("../components/Videos.vue"),
    meta: {
      title: "Videos",
      needsAuth: false,
      verified: false,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      // guest: true,
    },
  },

  {
    path: "/videos/:id",
    name: "video",
    component: () => import("../components/VideoSingle.vue"),
    meta: {
      title: "Video",
      needsAuth: false,
      verified: false,
      // roles: [
      //   "student",
      //   "teacher",
      //   "manager",
      //   "moderator",
      //   "admin",
      //   "superadmin",
      // ],
      // guest: false,
    },
  },
];
