import localforage from "localforage";
import { isEmpty } from "lodash";

export const RESET_STATE = (state) => {
  state.heartedFeedIds = [];
  state.heartedFeedCommentIds = [];
};

export const SET_HEARTED_FEED_IDS = (state, data) => {
  state.heartedFeedIds = data;
};
export const SET_HEARTED_FEED_COMMENT_IDS = (state, data) => {
  state.heartedFeedCommentIds = data;
};

export const PATCH_HEARTED_FEED_IDS = (
  state,
  { add = null, remove = null }
) => {
  if (add && parseInt(add) > 0) {
    state.heartedFeedIds = state.heartedFeedIds.filter((id) => id != add);
    state.heartedFeedIds.push(add);
  }
  if (remove && parseInt(remove) > 0) {
    state.heartedFeedIds = state.heartedFeedIds.filter((id) => id != remove);
  }
};

export const PATCH_HEARTED_FEED_COMMENT_IDS = (
  state,
  { add = null, remove = null }
) => {
  if (add && parseInt(add) > 0) {
    state.heartedFeedCommentIds = state.heartedFeedCommentIds.filter(
      (id) => id != add
    );
    state.heartedFeedCommentIds.push(add);
  }
  if (remove && parseInt(remove) > 0) {
    state.heartedFeedCommentIds = state.heartedFeedCommentIds.filter(
      (id) => id != remove
    );
  }
};
