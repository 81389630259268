import store from "@/store";
import localforage from "localforage";
import bus from "@/bus";
import rolesHandler from "./rolesHandler";
import { areObjectsEqual } from "@/helpers/utils";
const beforeEach = (to, from, next) => {
  // remove/ modify bottom check if redirect does not work properly
  if (
    to.name == from.name &&
    areObjectsEqual(to.params, from.params) &&
    areObjectsEqual(to.query, from.query) &&
    to.hash == from.hash
  ) {
    return;
  }
  // clear form errors everytime a route change/updates
  store.dispatch("setFormErrors", { errors: [] }, { root: true });

  // redirect back if route does not exists
  if (!to.matched.length) {
    store.dispatch(
      "setAlert",
      { type: "warning", message: to.name + " does not exists." },
      { root: true }
    );
    next({ path: from.path });
    return;
  }

  // ## forgot what it is, remove if not needed or uncomment it.
  // replaced with `this.$route.name` in BottomNav.vue
  // store.dispatch('setCurrentRoute',to)

  // check if user is loggedin or not
  store
    .dispatch("auth/getUserFromLocalStorage")
    .then((user) => {
      // auth true, now check if route is for guests only
      if (to.meta.guest) {
        // only guest can access this page
        // back to from page
        store.dispatch("setAlert", {
          type: "info",
          message: "You are already Logged in.",
        });
        next({ path: from.path });
        return;
      }

      // fetch user info
      // auth true, now check if route is for verified only
      if (to.meta.verified && !user.verified) {
        // only verified can access this page
        // back to from page
        store.dispatch("setAlert", {
          type: "error",
          message: "Please verify your account before accessing this page.",
        });

        // figureout which route to go to for verification
        if (user.mobile || (user.temp_mobile && !user.mobile_verified)) {
          if (!user.email_verified) {
            next({ name: "verify-mobile" });
          } else {
            next({ name: "verify-email" });
          }
        } else {
          next({ name: "verify-email" });
        }
        return;
      }

      // pages that needs verified mobile will go here
      if (to.meta.mobile_verified && !user.mobile_verified) {
      }

      /** user verified now check for mobile/email verification status */

      // pages that needs verified email will go here
      if (to.meta.email_verified && !user.email_verified) {
      }

      // verified user, now check route's access level
      if (!rolesHandler({ route: to, user })) {
        // only verified can access this page
        // back to from page
        store.dispatch("setAlert", {
          type: "error",
          message: `You are ${user.role.toUpperCase()}', You can not access this resource.`,
        });
        next({ path: from.path });
        return;
      }

      // auth true, all ok, emit routed event and proceed to next()
      bus.$emit("routed", to);
      next();
    })
    .catch((err) => {
      console.error("err", err);
      // auth false, check if route needsAuth or not
      if (to.meta.needsAuth) {
        // auth false, but needs auth, save intended to local storage
        // and redirect to login page.
        localforage.setItem("intended", {
          name: to.name,
          path: to.path,
          hash: to.hash,
          query: to.query,
          params: to.params,
          fullPath: to.fullPath,
        });
        store.dispatch(
          "setAlert",
          { type: "info", message: "AUTH_REQUIRED", translate: true },
          { root: true }
        );
        next({ name: "register" });
        return;
      }

      // auth false, route does not need auth, all ok,
      // emit routed event and continue to next()
      bus.$emit("routed", to);
      next();
    });
};

export default beforeEach;
