export default {
  RESULT: "Result",
  EXAMS: "Exams",
  ATTEMPTRESULT: "Test Result",
  HASTESTS: "Total %s tests",
  DIRECTIONS: "Directions",
  SUBMITCONFIRMTITLE: "Are you ready?",
  SUBMITCONFIRMTEXT: "Once you submit this test, you can not attempt it again.",
  SHOWANALYSIS: "Show Analysis",
  POINTSOBTAINED: "Points Obtained",
  UNLOCKINCOINS: "Unlock in %s coins",
  PURCHASEMOREVCOIN: "Purchase more vCoins",
  AVAILABLEEXAMS: "Available Exams",
  AVAILABLETESTS: "Available Tests",
  CHOOSEEXAM: "Choose An Exam",
  CHOOSETESTFROMBELOW: "Please choose any test from below.",
  CHOOSEEXAMFROMBELOW: "Please choose any exam category from below.",
};
