import router from "@/router/index";
import { App } from "@capacitor/app";

App.addListener("appUrlOpen", function(data) {
  // Example url: https://app.vikarn.com/exams/22
  // slug = /exams/22
  const slug = data.url.split("vikarn.com").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

App.addListener("appStateChange", ({ isActive }) => {
  console.log("App state changed. Is active?", isActive);
});

App.addListener("appRestoredResult", (data) => {
  console.log("Restored state:", data);
});

const checkAppLaunchUrl = async () => {
  const { url } = await App.getLaunchUrl();

  console.log("App opened with URL: " + url);
};
