<template>
  <div v-if="item && item.id && item.id > 0 && isVisible">
    <v-alert
      transition="fade-transition"
      @click="handleAction"
      :value="showAlert"
      :type="item.type"
    >
      <div v-if="item.title || item.title_hi">
        {{
          isHindi ? (item.title_hi ? item.title_hi : item.title) : item.title
        }}
      </div>
      <div class="caption" v-if="item.description || item.description_hi">
        <v-divider class="my-2"></v-divider>
        {{
          isHindi
            ? item.description_hi
              ? item.description_hi
              : item.description
            : item.description
        }}
      </div>
    </v-alert>
  </div>
</template>

<script>
import bus from "@/bus";
import { Device } from "@capacitor/device";

import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import { Browser } from "@capacitor/browser";

export default {
  data() {
    return {
      device: null,
      showAlert: false,
      item: null,
      item2: {
        id: 1,
        type: "info",
        title:
          "We have made many important changes in our app. Update now to experience the app at its best!",
        title_hi: `हमने अपनी एप मे बहुत से महत्वपूर्ण बदलाव किए हैं। ऐप्लकैशन का ठीक से अनुभव करने के लिए अभी अपडेट करें!`,
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde odit, facilis quaerat, sequi veritatis, aliquam temporibus aut maxime facere pariatur dolore eum labore nisi tenetur commodi minus inventore! Cupiditate, dignissimos.",
        description_hi:
          "लोरेम इप्सम डोलर सिट एमेट कंसेक्टेटूर एडिपिसिसिंग एलीट। अंडर ओडिट, फैसिलिस क्वाराट, सेक्वी वेरिटैटिस, एलिकम टेम्पोरिबस ऑट मैक्सिमे फेसरे परियातुर डोलोरे ईम लेबर निसी टेनटूर कमोडिटी माइनस इन्वेंटरी!",
        uri: null,
        visible_for: [1],
        hidden_for: [],
        closeable: false,
        duration: 10,
        action: {
          uri: "https://play.google.com/store/apps/details?id=com.vikarn.app.client",
          external: true,
        },
        appBuild: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    user_id() {
      return this.authUser ? this.authUser.id : 0;
    },
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
    isDark() {
      return this.$store.getters.isThemeDark;
    },

    isVisible() {
      if (!this.item || !this.item.id) {
        return false;
      }

      if (this.device && this.device.platform != "web") {
        if (
          this.device.appBuild &&
          this.device.appBuild >= this.item.appBuild
        ) {
          return false;
        }
      }

      if (this.item && this.item.androidOnly) {
        //console.log("got alert for android app only");
        return false;
      }

      if (this.item.hidden_for && this.item.hidden_for.length > 0) {
        if (this.item.hidden_for.includes(this.user_id)) {
          return false;
        }
        return true;
      }
      if (this.item.visible_for && this.item.visible_for.length > 0) {
        if (this.item.visible_for.includes(this.user_id)) {
          return true;
        }
        return false;
      }

      if (
        (!this.item.visible_for || this.item.visible_for.length < 1) &&
        (!this.item.hidden_for || this.item.hidden_for.length < 1)
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    async handleExternal(action) {
      await Browser.open({ url: action.uri });
    },
    handleInternal(action) {
      this.$router.push({ path: action.uri });
    },
    handleAction() {
      if (!this.item || !this.item.id || !this.item.action) {
        return;
      }
      if (this.item.closeable) {
        this.showAlert = false;
        return false;
      }
      if (!this.item.action || !this.item.action.uri) {
        return false;
      }

      if (this.item.action.external) {
        this.handleExternal(this.item.action);
      } else {
        this.handleInternal(this.item.action);
      }
      this.showAlert = false;
    },
    fetchNotification() {
      return axios
        .post("globals/app_notification", { device: this.device })
        .then((res) => {
          this.item = res.data;
          setTimeout(() => {
            this.showAlert = true;
          }, 500);

          if (this.item.duration && this.item.duration > 0) {
            setTimeout(() => {
              this.showAlert = false;
              setTimeout(() => {
                this.item = null;
              }, 1000);
            }, this.item.duration * 1000);
          }
        })
        .catch((err) => {
          this.item = null;
        });
    },
  },
  async created() {
    this.device = await Device.getInfo();
  },
  mounted() {
    setTimeout(() => {
      if (this.authUser) {
        this.fetchNotification();
      }
    }, 4000);
  },
};
</script>

<style scoped>
.v-alert {
  cursor: pointer;
}
</style>