import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import exams from "../exams/store";
import home from "../home/store";
import doubts from "../doubts/store";
import notes from "../notes/store";
import profile from "../profile/store";
import feed from "../feed/store";
import learn from "../learn/store";
import rewards from "../rewards/store";
import notifications from "../notifications/store";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    exams,
    home,
    doubts,
    notes,
    profile,
    feed,
    learn,
    rewards,
    notifications,
  },
};
