<template>
  <v-dialog width="500" persistent v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-list class="rounded-xl" v-if="authUser.referee_uid">
        <div class="overline pl-5">{{ $lang("youarereferredby") }}</div>

        <v-list-item>
          <v-list-item-avatar
            @click.stop="
              $router.push({
                name: 'profile',
                params: { id: authUser.referee.id },
              })
            "
          >
            <v-avatar contain size="64">
              <v-img style="width: 100%" :src="authUser.referee.avatar" />
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              @click.stop="
                $router.push({
                  name: 'profile',
                  params: { id: authUser.referee.id },
                })
              "
              class="title"
              >{{ authUser.referee.display_name }}</v-list-item-title
            >
            <v-list-item-subtitle
              >vikarn.com/{{ authUser.referee.username }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click.stop="
                $router.push({
                  name: 'profile',
                  params: { id: authUser.referee.id },
                })
              "
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card v-else class="rounded-xl text-center coupon-code">
        <v-card-title class="justify-center">
          {{ $lang("referredbysomeone") }}
        </v-card-title>
        <v-card-text>{{ $lang("REFERREDBYSOMEONE_HINT") }}</v-card-text>
        <v-card-actions>
          <v-btn class="rounded-xl" block v-bind="attrs" v-on="on">
            {{ $lang("enterreferralcode") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- {{ $lang("youarereferredby", [authUser.referee.display_name]) }} -->
    </template>
    <v-card>
      <v-card-title class="justify-center">
        {{ $lang("referredbysomeone") }}
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" icon @click="handleClose"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>{{ $lang("REFERREDBYSOMEONE_HINT") }}</v-card-text>
      <v-card-text>
        <v-text-field
          placeholder="Referral Code"
          class="rounded-xl"
          outlined
          @keyup.enter="doSubmit"
          v-model="referralCode"
          type="text"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          x-large
          :loading="loading"
          class="rounded-xl"
          block
          @click="doSubmit"
          >{{ $lang("submit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
export default {
  data() {
    return {
      referralCode: null,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.loading = false;
      this.referralCode = null;
    },
    doSubmit() {
      if (
        !this.referralCode ||
        !this.referralCode.trim() ||
        this.referralCode.trim().length < 1
      ) {
        console.log("enter code");
        return;
      }

      if (this.authUser.uid == this.referralCode) {
        this.referralCode = null;
        return;
      }

      this.loading = true;

      axios
        .get("auth/attach/referee/" + this.referralCode.trim())
        .then((res) => {
          this.$emit("sumitted", true);
          this.$store.dispatch("auth/fetchUser");
          this.dialog = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
};
</script>

<style>
</style>