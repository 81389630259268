export default [
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../components/Notifications.vue"),
    meta: {
      title: "Notifications",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
  {
    path: "/notifications/:id",
    name: "notification",
    component: () => import("../components/Notification.vue"),
    meta: {
      title: "Notification",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
