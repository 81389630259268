export default {
  PAYMENTS: "Payments",
  PAYMENT: "Payment",
  BROWSESTORE: "Browse Store",
  PURCHASE: "Purchase",
  PAYMENTHELP: "Payment Help",
  ORDERSTATUS: "Order Status",
  REDEEMCOUPON: "Redeem Coupon",
  REDEEM: "Redeem",
  COUPON: "Coupon",
  ENTERCOUPONCODE: "Enter Coupon Code",
  REFERRALPROGRAM: "Referral Program",
  COINSCREDITEDYOURWALLET: "%s coins have been credited to your wallet.",
  YOUGETCOINS: "You will get %s coins with this coupon.",
  REFERREDBYSOMEONE: "Someone referred you?",
  REFERREDBYSOMEONE_HINT:
    "In-case someone referred you, you can enter their refferal code below you both will get a coupon each.",
  YOUAREREFERREDBY: "You are referred by",
  YOURREFERRALCODE: "Your referral code",
  REFERFRIENDSEARNCOINS: "Refer friends, earn coins.",
  ENTERREFERRALCODE: "Enter referral code",
};
