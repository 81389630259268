<template>
  <div>
    <v-list-item
      @click="$router.push({ name: 'iap' })"
      v-if="
        authUser &&
        ['superadmin', 'admin'].includes(authUser.role) &&
        info &&
        info.platform == 'android'
      "
    >
      <v-list-item-title> In App Purchase </v-list-item-title>
    </v-list-item>

    <v-list-item
      class="animate__animated animate__bounce animate__delay-2s"
      v-if="info && info.platform == 'web'"
      @click.stop="$router.push({ name: 'donate' })"
    >
      <v-list-item-icon>
        <v-icon>mdi-hand-heart</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          {{ isHindi ? "दान करें। " : "Donate!" }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      class="animate__animated animate__lightSpeedInLeft animate__delay-1s"
      v-else
      @click.stop="openLink"
    >
      <v-list-item-icon>
        <v-icon color="orange">mdi-star</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{
            isHindi ? "हमें प्ले स्टोर पर रेट करें। " : "Rate us on Play Store"
          }}</v-list-item-title
        >
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon>mdi-arrow-right</v-icon>
      </v-list-item-icon>

      <ratings v-if="false" />
    </v-list-item>
  </div>
</template>

<script>
import bus from "@/bus";
import { Device } from "@capacitor/device";

import { handleClick } from "./payment";
import { mapGetters } from "vuex";
import Ratings from "./Ratings";
import { Browser } from "@capacitor/browser";
export default {
  name: "donate-button",
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  components: {
    Ratings,
  },
  data() {
    return {
      info: null,
    };
  },
  methods: {
    openLink() {
      Browser.open({
        url: "https://play.google.com/store/apps/details?id=com.vikarn.app.client&showAllReviews=true",
      });
    },

    handleClick() {
      if (this.authUser && this.authUser.id) {
        handleClick({ amount: 1000, user: this.authUser }).catch((err) => {
          console.error(err);
        });
      }
    },
  },
  async created() {
    this.info = await Device.getInfo();
    // console.log("device", this.info);
  },
};
</script>

<style>
</style>