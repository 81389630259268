import { axios, setHttpToken } from "@/plugins/axios.js";
import { isEmpty } from "lodash";
import localforage from "localforage";
import bus from "@/bus";
import { locale } from "core-js";
import store from "@/store";

function dispatchErrorAlert(error) {
  store.dispatch("apiAlert", {
    message:
      error.response.data && error.response.data.message
        ? error.response.data.message
        : error.response.status,
    code: error.response.status,
  });
}

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};
