import g2048 from "./g2048/routes";
import SignsPractice from "./signs-practice/routes";

export default [
  {
    path: "/games",
    name: "games",
    component: () => import("./GameIndex.vue"),
    meta: {
      needsAuth: false,
      verified: false,
      guest: false,
      // roles,
    },
  },
  ...g2048,
  ...SignsPractice,
];
