<template>
  <v-system-bar
    fixed
    app
    transition="slide-y-transition"
    color="outline orange darken-4 white--text"
    class="verify-bar"
    v-if="
      user &&
      user.id &&
      !user.verified &&
      $route.name != 'verify-email' &&
      $route.name != 'verify-mobile'
    "
  >
    <v-layout @click="handleClick">
      <v-icon class="mr-2">mdi-alert</v-icon>
      {{ $lang("VerifyStatusText") }}
    </v-layout>
  </v-system-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    handleClick() {
      if (!this.user && !this.user.id) {
        return;
      }
      if (
        this.user.mobile ||
        (this.user.temp_mobile && !this.user.mobile_verified)
      ) {
        if (!this.user.email_verified) {
          this.$router.replace({ name: "verify-mobile" });
        } else {
          this.$router.replace({ name: "verify-email" });
        }
      } else {
        this.$router.replace({ name: "verify-email" });
      }
    },
  },
};
</script>
<style scoped>
.verify-bar {
  cursor: pointer;
}
</style>
