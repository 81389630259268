<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="500px"
      scrollable
    >
      <v-card>
        <v-toolbar style="flex: 0 0 auto" flat>
          <v-btn icon @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $lang("SETTINGS") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-subheader>{{ $lang("USER_PREFS") }}</v-subheader>
          <v-select
            :items="locales"
            v-model="selected"
            prepend-icon="mdi-translate"
            :label="$lang('LANGUAGE')"
            single-line
            outlined
          ></v-select>
          <v-divider></v-divider>
          <v-list subheader ripple>
            <v-subheader>{{ $lang("GENERAL") }}</v-subheader>
            <v-list-item-group>
              <v-list-item @click.stop="$store.dispatch('toggleTheme')">
                <v-list-item-icon>
                  <v-icon>{{
                    $store.getters.isThemeDark
                      ? "mdi-brightness-7"
                      : "mdi-brightness-2"
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $store.getters.isThemeDark
                      ? $lang("LIGHT_THEME")
                      : $lang("DARK_THEME")
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action> </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { setLocale, getLocale } from "@/locales";
export default {
  props: ["showDialog"],
  name: "settings-dialoge",
  data() {
    return {
      dialog: null,
      isThemeDark: false,
      selected: getLocale(),
      locales: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "हिंदी",
          value: "hi",
        },
      ],
    };
  },
  watch: {},
  computed: {
    currTheme: {
      set() {},
      get() {
        return this.$store.getters.isThemeDark;
      },
    },
  },
  watch: {
    selected(val) {
      if (val) {
        setLocale(val);
      }
    },
    showDialog: function () {
      this.dialog = this.showDialog;
    },
    dialog: function () {
      this.selected = getLocale();
      if (!this.dialog) this.$emit("closed");
    },
  },
  created() {
    this.selected = getLocale();
  },
};
</script>