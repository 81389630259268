import { AdMob } from "@capacitor-community/admob";

export async function initialize() {
  const { status } = await AdMob.trackingAuthorizationStatus();

  if (status === "notDetermined") {
    /**
     * If you want to explain TrackingAuthorization before showing the iOS dialog,
     * you can show the modal here.
     * ex)
     * const modal = await this.modalCtrl.create({
     *   component: RequestTrackingPage,
     * });
     * await modal.present();
     * await modal.onDidDismiss();  // Wait for close modal
     **/
  }

  return await AdMob.initialize({
    requestTrackingAuthorization: true,
    testingDevices: ["90CB1AADA09841091848E80A33FD52C6"],
    // initializeForTesting: true,
  });
}
export default {
  async mounted() {},
};
