import localforage from "localforage";
import { isEmpty } from "lodash";

export const SET_ITEMS = (state, { items }) => {
  state.items = items;
};

export const RESET_STATE = (state) => {
  state.score = [];
};
