import localforage from "localforage";
import { isEmpty } from "lodash";

export const RESET_STATE = (state) => {
  state.subjects = [];
  state.categories = [];
  state.questions = [];
};

export const SET_SUBJECTS = (state, data) => {
  state.subjects = data;
};
export const SET_BOOKMARKS = (state, items) => {
  if (!items || items.length < 1) {
    items = [];
  }
  state.bookmarks = items;
  localforage.setItem("app/learn/bookmarks", state.bookmarks);
};

export const TOGGLE_BOOKMARK = (state, item) => {
  const isBookmarked = state.bookmarks.find((el) => el == item.id);
  if (isBookmarked) {
    state.bookmarks = state.bookmarks.filter((el) => el != item.id);
  } else {
    state.bookmarks.push(item.id);
  }

  localforage.setItem("app/learn/bookmarks", state.bookmarks);
};
