let roles = [
  "student",
  "teacher",
  "manager",
  "moderator",
  "admin",
  "superadmin",
];
export default [
  {
    path: "/exams",
    name: "exams",
    component: () => import("../components/Exams"),
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  // {
  //   path: "/exams/:id",
  //   name: "exams-children",
  //   component: () => import("../components/ExamsChildren"),
  //   meta: {
  //     title: "EXAMS",
  //     needsAuth: true,
  //     verified: true,
  //     roles,
  //     guest: false,
  //   },
  // },
  {
    path: "/exams/:id",
    name: "exam",
    component: () => import("../components/Exam"),
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/mocktest-preview/:id",
    name: "exams-mocktest-preview",
    component: () => import("../components/MocktestPreview"),
    meta: {
      title: "Mocktest",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/mocktest-unlock/:id",
    name: "exams-mocktest-unlock",
    component: () => import("../components/MocktestUnlock"),
    meta: {
      title: "Mocktest locked",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/mocktest/:id",
    name: "exams-mocktest",
    component: () => import("../components/Mocktest"),
    meta: {
      title: "EXAMS",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
  {
    path: "/exams/attempt/result/:id",
    name: "exams-attempt-result",
    component: () => import("../components/Attempt/Result"),
    meta: {
      title: "ATTEMPTRESULT",
      needsAuth: true,
      verified: true,
      roles,
      guest: false,
    },
  },
];
