export default [
  {
    path: "/",
    name: "home",
    component: () => import("../components/Home/index.vue"),
    meta: {
      title: "HOME",
      needsAuth: false,
      verified: false,
      guest: false,
    },
  },
];
