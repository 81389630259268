export default [
  {
    path: "/learn",
    name: "learn",
    component: () => import("../components/Subjects.vue"),
    meta: {
      title: "Learn",
    },
  },
  {
    path: "/learn/:subject",
    name: "learn-categories",
    component: () => import("../components/SubjectCategories.vue"),
    meta: {
      title: "Learn",
    },
  },
  {
    path: "/learn/:subject/:category",
    name: "learn-questions",
    component: () => import("../components/CategoryQuestions.vue"),
    meta: {
      title: "Learn",
      // needsAuth: true,
    },
  },
];
