export const SET_CURRENT_ROUTE = (state, route) => {
  state.currRoute = route;
};
export const SET_CURR_LOCALE = (state, locale) => {
  state.currLocale = locale;
};

export const SET_THEME_DARK = (state, trueOrFalse) => {
  state.isThemeDark = trueOrFalse;
};

export const RESET_STATE = (state) => {
  state.currRoute = null;
  state.isThemeDark = false;
  state.alert = {
    type: null,
    message: null,
    icon: null,
    isHTML: false,
    uri: null,
    prominent: false,
  };
};

export const SET_ALERT = (
  state,
  {
    type,
    message,
    icon = null,
    translate = false,
    isHTML = false,
    uri = null,
    prominent = false,
  }
) => {
  state.alert = {
    type: type,
    message: message,
    icon: icon,
    translate: translate,
    isHTML: isHTML,
    uri: uri,
    prominent: prominent,
  };
};

export const SET_FORM_ERRORS = (state, { errors }) => {
  state.formErrors = errors;
};
