export default [
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../components/Jobs.vue"),
    meta: {
      title: "JOBS",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
  {
    path: "/jobs/:id",
    name: "job",
    component: () => import("../components/Job.vue"),
    meta: {
      title: "JOB",
      needsAuth: true,
      verified: true,
      roles: [
        "student",
        "teacher",
        "manager",
        "moderator",
        "admin",
        "superadmin",
      ],
      guest: false,
    },
  },
];
