<template>
  <v-overlay v-if="overlay" class="black" :z-index="100" :value="overlay">
    <v-card v-if="$store.getters.getCurrLocale == 'hi'">
      <v-card-title> इंटरनेट से कनेक्ट नहीं हैं। </v-card-title>
      <v-card-text>
        <div>
          विकर्ण को काम करने के लिए एक सक्रिय इंटरनेट कनेक्शन की आवश्यकता होती
          है।
        </div>
        <div>कृपया इंटरनेट से कनेक्ट करें।</div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title> Not connected to the internet. </v-card-title>
      <v-card-text>
        <div>Vikarn requires an active internet connection to work.</div>
        <div>Please connect to the internet.</div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
export default {
  name: "offline-status",
  data() {
    return {};
  },
  computed: {
    overlay() {
      return !this.$online;
    },
  },
  methods: {
    handleClick() {
      window.location.reload();
    },
  },
};
</script>
