import { MainIndex } from "../components";
const roles = [
  "admin",
  "superadmin",
  "manager",
  "moderator",
  "teacher",
  "parent",
  "student",
  "member",
];
export default [
  {
    path: "/games/2048",
    name: "games-2048",
    component: MainIndex,
    meta: {
      needsAuth: true,
      verified: true,
      guest: false,
      roles,
    },
  },
];
