import g2048 from "./g2048/locales";
import SignsPractice from "./signs-practice/locales";

export default {
  en: {
    ...g2048.en,
    ...SignsPractice.en,
  },
  hi: {
    ...g2048.hi,
    ...SignsPractice.hi,
  },
};
