export default [
  // {
  //   path: "/rewards",
  //   name: "rewards",
  //   component: () => import("../components/Rewards.vue"),
  //   meta: {
  //     title: "rewards",
  //     needsAuth: true,
  //     verified: true,
  //     guest: false,
  //   },
  // },
];
