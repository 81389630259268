import Vue from "vue";
import html2canvas from "html2canvas";

const Html2Canvas = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        async $html2canvas(el, options = {}) {
          const { type } = options;
          const canvas = await html2canvas(el, options);
          if (type && type === "dataURL") {
            return canvas.toDataURL();
          } else {
            console.warn(
              "Html2Canvas Warn: Invalid option type. Use 'dataURL' instead. Returning canvas."
            );
            return canvas;
          }
        },
      },
    });
  },
};

Vue.use(Html2Canvas);

export default Html2Canvas;
