export default [
  {
    path: "/iap",
    name: "iap",
    component: () => import("../components/IAP.vue"),
    meta: {
      title: "In App Purchase",
      needsAuth: true,
      verified: true,
      roles: ["admin", "superadmin"],
      guest: false,
    },
  },
];
