import localforage from "localforage";
import { isEmpty } from "lodash";

export const RESET_STATE = (state) => {
  state = {};
};

export const SET_SCORE = (state, score) => {
  state.score = score;
};
