import localforage from "@/plugins/localforage";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import router from "@/router";
import bus from "@/bus";

//persist current locale
localforage.getItem("currLocale").then((val) => {
  store.dispatch("setCurrLocale", val);
});

//persist app/learn/bookmarks
localforage.getItem("app/learn/bookmarks").then((val) => {
  store.dispatch("app/learn/setBookmarks", val);
});

// persisting theme
localforage.getItem("isThemeDark").then((val) => {
  store.dispatch("setThemeDark", val);
  vuetify.framework.theme.dark = val;
});

localforage.getItem("activeMocktests").then((val) => {
  if (!val) val = [];
  store.dispatch("app/exams/RestoreActiveMocktestElapsed", val);
});

localforage.getItem("mocktests").then((val) => {
  if (!val) val = [];
  store.dispatch("app/exams/RestoreMocktests", val);
});

// persisting authentication
store
  .dispatch("auth/setToken")
  .then(() => {
    store
      .dispatch("auth/fetchUser")
      .then((res) => {
        //persist reactions
        store.dispatch("app/feed/fetchHeartedFeedIds");
        store.dispatch("app/feed/fetchHeartedFeedCommentIds");
      })
      .catch((err) => {
        if (err == "NOT_FOUND") {
          store.dispatch("auth/clearAuth");
          router.replace({ name: "login" });
        }
      });
  })
  .catch((err) => {
    // no auth token
    store.dispatch("auth/clearAuth");
  });
