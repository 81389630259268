<template>
  <v-alert
    :prominent="$store.getters.getAlert.prominent ? true : false"
    :value="$store.getters.getAlert.message ? true : false"
    :color="$store.getters.getAlert.type"
    :icon="
      $store.getters.getAlert.icon ? $store.getters.getAlert.icon : 'mdi-alert'
    "
    transition="slide-y-transition"
    class="top-fixed-alert"
    @click="
      () => {
        if ($store.getters.getAlert.uri) {
          $router.push({ path: $store.getters.getAlert.uri });
          $store.dispatch('clearAlert');
        }
      }
    "
  >
    <span
      v-if="$store.getters.getAlert.isHTML"
      v-html="$store.getters.getAlert.message"
    ></span>
    <span v-else>
      {{
        $store.getters.getAlert.translate
          ? $lang($store.getters.getAlert.message)
          : $store.getters.getAlert.message
      }}
    </span>
  </v-alert>
</template>

<script>
export default {
  name: "alert-bar",
};
</script>
<style scoped>
.top-fixed-alert {
  position: fixed;
  top: -4px;
  z-index: 10000;
  width: 100%;
}
</style>
