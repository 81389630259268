<template>
  <v-card class="d-flex flex-column" elevation="0">
    <v-card-text>
      <v-img src="/artwork/help.png" />
    </v-card-text>
    <v-card-title class="headline">MOCK TEST & EXAM PREP</v-card-title>
    <v-card-text class="title"> Education to Excellence </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>