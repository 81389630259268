<template>
  <v-navigation-drawer app class="py-0" v-model="drawer">
    <v-layout column fill-height>
      <v-list>
        <v-list-item>
          <v-list-item-avatar v-if="auth.authenticated">
            <v-avatar
              size="64"
              class="rounded-circle elevation-1 d-inline-block"
            >
              <drawer-avatar-img style="cursor: pointer" />
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content v-if="auth.authenticated">
            <v-list-item-title class="title">{{
              auth.user.profile.display_name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ auth.user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content v-if="!auth.authenticated">
            <v-list-item-title>Guest</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click.stop="
                drawer = false;
                showSettings = true;
              "
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense class="py-0" subheader v-if="auth.authenticated">
        <v-subheader>{{ $lang("Help") }}</v-subheader>
        <donate-list-item />
      </v-list>
      <v-spacer></v-spacer>
      <v-list nav dense class="py-0" subheader v-if="auth.authenticated">
        <v-subheader>{{ $lang("Settings") }}</v-subheader>
        <v-list-item-group v-model="selected_menu_setting" color="primary">
          <v-list-item
            v-for="item in menu_settings"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-badge
                overlay
                v-if="item.isColor"
                :color="item.isColor"
                dot
              ></v-badge>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ translate(item) }}</v-list-item-title>
            </v-list-item-content>
            <v-chip
              overlay
              right
              x-small
              v-if="item.isNewColor"
              :color="item.isNewColor"
              >new</v-chip
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list nav dense class="py-0" subheader>
        <v-subheader>{{ $lang("ACCOUNT") }}</v-subheader>
        <v-list-item-group
          v-model="selected_menu_user"
          color="primary"
          v-if="auth.authenticated"
        >
          <v-list-item
            v-for="item in menu_user"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-badge
                overlay
                v-if="item.isColor"
                :color="item.isColor"
                dot
              ></v-badge>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{ translate(item) }}</v-list-item-title>
            </v-list-item-content>
            <v-chip
              overlay
              right
              x-small
              v-if="item.isNewColor"
              :color="item.isNewColor"
              >new</v-chip
            >
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-model="selected_menu_guest" color="primary" v-else>
          <v-list-item
            v-for="item in menu_guest"
            :key="item.icon"
            @click="item.to"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ translate(item) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <app-version />
    </v-layout>
    <settings-dialoge
      :showDialog="showSettings"
      @closed="showSettings = false"
    ></settings-dialoge>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsDialoge from "@/components/SettingsDialoge";
import DrawerAvatarImg from "@/components/DrawerAvatarImg";
import AppVersion from "./AppVersion";
import DonateListItem from "@/app/payment/modules/DonateListItem";

export default {
  components: {
    SettingsDialoge,
    DrawerAvatarImg,
    AppVersion,
    DonateListItem,
  },
  data() {
    return {
      drawer: null,
      showSettings: false,
      selected_menu_guest: null,
      selected_menu_user: null,
      selected_menu_setting: null,
    };
  },

  watch: {
    "$route.name"(name) {
      if (["login", "register", "forgot-password"].includes(name)) {
        this.drawer = false;
      }
    },
    showDrawer: function () {
      if (["login", "register", "forgot-password"].includes(this.$route.name)) {
        this.drawer = false;
        return;
      }
      this.drawer = this.showDrawer;
    },
    drawer: function () {
      if (this.drawer == false) this.$emit("drawerFalse");
    },
  },
  props: ["showDrawer"],
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
    menu_guest() {
      return [
        {
          icon: "mdi-lock",
          text: "Log in",
          text_hi: "लॉग इन",
          to: () => this.routeTo("login"),
        },
        {
          icon: "mdi-account",
          text: "Register",
          text_hi: "रजिस्टर",
          to: () => this.routeTo("register"),
        },
        {
          icon: "mdi-help",
          text: "Help & Feedback",
          text_hi: "हेल्प एवम् फीडबैक",
          to: () => this.routeTo("help"),
        },
      ];
    },
    menu_user() {
      return [
        {
          icon: "mdi-ticket-percent",
          text: "Redeem Coupon",
          text_hi: "कूपन रीडीम कराएं",
          to: () => this.routeTo("redeem-coupon"),
          // isColor: "pink",
          // isNewColor: "pink white--text",
        },
        {
          icon: "mdi-cart",
          text: "My Orders",
          text_hi: "मेरे ऑर्डर्स",
          to: () => this.routeTo("my-orders"),
          // isColor: "pink",
          // isNewColor: "pink white--text",
        },
        {
          icon: "mdi-account-box",
          text: "Update Profile",
          text_hi: "प्रोफाइल अपडेट",
          to: () => this.routeTo("update-profile"),
        },
        {
          icon: "mdi-email",
          text: "Update Email",
          text_hi: "ईमेल बदलें",
          to: () => this.routeTo("update-email"),
        },
        {
          icon: "mdi-phone-plus",
          text: this.authUser.mobile ? "Update Mobile" : "Add Mobile",
          text_hi: this.authUser.mobile ? "मोबाईल बदलें" : "मोबाईल जोड़ें",
          to: () => this.routeTo("update-mobile"),
          // isColor: "pink",
          // isNewColor: "pink white--text",
        },

        {
          icon: "mdi-lock",
          text: "Update Password",
          text_hi: "पासवर्ड बदलें",
          to: () => this.routeTo("update-password"),
        },
        {
          icon: "mdi-ticket",
          text: "Referral Program",
          text_hi: "रेफरल प्रोग्राम",
          to: () => this.routeTo("referral-program"),
          // isColor: "orange",
          // isNewColor: "pink white--text",
        },
        {
          icon: "mdi-delete",
          text: "Remove Account",
          text_hi: "अकाउंट हटायें",
          // isNewColor: "pink white--text",
          to: () => this.routeTo("remove-account"),
        },
        {
          icon: "mdi-help",
          text: "Help & Feedback",
          text_hi: "हेल्प एवम् फीडबैक",
          to: () => this.routeTo("help"),
        },        
        {
          icon: "mdi-logout",
          text: "Log out",
          text_hi: "लॉग आउट",
          to: () => this.routeTo("logout"), //this.signout
        },
      ];
    },
    menu_settings() {
      return [
        {
          icon: "mdi-account-cancel",
          text: "Blocked Users",
          text_hi: "ब्लॉक किए हुए यूजर्स",
          to: () => this.routeTo("blocked-users"),
          // isNewColor: "pink white--text",
        },
        {
          icon: this.$store.getters.isThemeDark
            ? "mdi-brightness-7"
            : "mdi-brightness-2",
          text: this.$store.getters.isThemeDark ? "Light Theme" : "Dark Theme",
          text_hi: this.$store.getters.isThemeDark ? "लाइट थीम" : "डार्क थीम",
          to: () => this.$store.dispatch("toggleTheme"),
          // isNewColor: "pink white--text",
        },
      ];
    },
    isAllowed() {
      let allowed = [
        "teacher",
        "parent",
        "student",
        "moderator",
        "manager",
        "admin",
        "superadmin",
      ];
      if (this.auth.user && allowed.includes(this.auth.user.role)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    translate(item) {
      if (this.$store.getters.getCurrLocale == "hi") {
        return item.text_hi;
      }
      return item.text;
    },
    routeTo(to) {
      this.$router.push({ name: to });
    },
  },
  created() {},
};
</script>

<style scoped>
</style>