<template>
  <v-app>
    <fullscreen-wait style="z-index: 10000" />
    <alert-bar />
    <offline-status />
    <app-drawer ref="drawer" />
    <app-bar
      :pageTitle="$route.meta.title"
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
      @hide-drawer="$refs.drawer.drawer = false"
    />

    <v-main :style="getStyleColor">
      <verify-status />
      <v-container fluid class="mb-12">
        <app-notification />
        <router-view :key="$route.fullPath" />
        <!-- <fab-button :icon="'mdi-plus'" color="primary"></fab-button> -->
        <Footer />
      </v-container>
      <privacy-modal />
    </v-main>
    <app-bottom-nav />
    <fcm-push-notification @received="(data) => {}" />
    <Setup />
    <UpdateProfileDialog v-if="isProfileUpdateDialogVisible" />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import AppDrawer from "./components/AppDrawer";
import AppBottomNav from "./components/AppBottomNav";
import FullscreenWait from "./components/FullscreenWait";
import AlertBar from "./components/AlertBar";
import VerifyStatus from "./components/VerifyStatus";
import Footer from "./components/Footer.vue";
import PrivacyModal from "./components/PrivacyModal";
// import FabButton from "./components/FabButton";
import FcmPushNotification from "@/components/FcmPushNotification";
import OfflineStatus from "./components/OfflineStatus";
import { axios } from "@/plugins/axios";
import { mapGetters } from "vuex";
import AppNotification from "@/components/AppNotification";
import UpdateProfileDialog from "@/app/auth/modules/UpdateProfileDialog";
import Setup from "@/components/Setup/index.vue";

export default {
  name: "App",

  components: {
    AppBar,
    AppDrawer,
    AppBottomNav,
    FullscreenWait,
    AlertBar,
    VerifyStatus,
    Footer,
    FcmPushNotification,
    PrivacyModal,
    OfflineStatus,
    AppNotification,
    Setup,
    UpdateProfileDialog,
  },
  data: () => ({
    drawer: false,
  }),
  watch: {
    isThemeDark(val) {
      this.$vuetify.theme.dark = val;
    },
    user(val) {},
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    getStyleColor() {
      return this.$store.getters.isThemeDark
        ? "background-color: #141414"
        : "background-color: #f9f9f9";
    },
    isThemeDark() {
      return this.$store.getters.isThemeDark;
    },

    isProfileUpdateDialogVisible(){

      if(!this.user) return false;
      if(!this.user.verified) return false;
      if(!this.user.profile) return false;

      return (!this.user.profile.first_name ||
          !this.user.profile.dob ||
          !this.user.profile.state ||
          !this.user.profile.gender)
    }
  },
  updated() {
    this.$nextTick(function () {
      this.queryUserForChanges();
    });
  },
  methods: {
    queryUserForChanges() {
      if (!this.user || !this.user.id) {
        return;
      }
      return axios
        .get("/auth/me")
        .then((res) => {
          const user = res.data;
          if (!user || !this.user) {
            return;
          }

          if (user.banned) {
            this.$router.replace({ name: "logout" });
            window.location.reload();
            return;
          }

          if (this.user.verified != user.verified) {
            this.$store.dispatch("auth/fetchUser");
          }

          if (this.user.role != user.role) {
            this.$store.dispatch("auth/fetchUser");
          }
        })
        .catch((err) => {
          console.log("MISSING OR INVALID TOKEN", "Logging Out");
          if (
            err.response.status &&
            err.response.status == 401 &&
            err.response &&
            err.response.data &&
            err.response.message &&
            err.response.data.message.toUpperCase() ==
              "MISSING OR INVALID TOKEN"
          ) {
            this.$router.replace({ name: "logout" });
          }
          // if(err.response.status)
          // works,
          // if (err.response && err.response.status == 403) {
          //   this.$router.replace({ name: "logout" });
          //   return;
          // }
        });
    },
  },
  async created() {},
  mounted() {
    setTimeout(() => {
      if (this.user && !this.user.verified) {
        const timeInterval = setInterval(() => {
          console.log(
            "User not verified, Chechking for verification status..."
          );
          axios
            .get("/auth/me")
            .then((res) => {
              if (res.data && res.data.verified) {
                console.log("User is verified.");
                this.$store.dispatch("auth/fetchUser");
                clearInterval(timeInterval);
              }
            })
            .catch((_) => {});
        }, 8000);
      }
    }, 2000);
  },
};
</script>
<style>
/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 10, 30, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}

/* wysiwyg scrollable-x for editor toolbar */
.editr div.editr--toolbar {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 2em;
}
.editr div.editr--toolbar::-webkit-scrollbar {
  max-height: 2px;
}

div.vue-dropzone {
  z-index: 1;
  position: absolute;
  top: -80px;
  right: 1em;
  width: 80px;
  overflow: hidden;
  background: lightgreen;
}

/* disable text selection */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                    supported by Chrome, Edge, Opera and Firefox */
}

.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

/* Shine animation for blocks */
.anim-shine {
  position: relative;
  overflow: hidden;
}

/**
 * The "shine" element
 */

.anim-shine:after {
  animation: shine2 5s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 250%;
  height: 350%;
  opacity: 0;
  transform: rotate(-45deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* Hover state - trigger effect */

/* Active state */

.anim-shine:active:after {
  opacity: 0;
}

@keyframes shine2 {
  50% {
    opacity: 0.5;
    top: -30%;
    left: -50%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -0%;
    transition-property: left, top, opacity;
  }
}

.anim-scale {
  animation: animscale 3s ease-in-out infinite alternate;
}

.anim-rotate-y {
  animation: animrotate-y 2s ease-in-out infinite;
}
.anim-rotate-x {
  animation: animrotate-x 2s ease-in-out infinite;
}
.anim-rotate-z {
  animation: animrotate-z 2s ease-in-out infinite;
}

@keyframes animrotate-y {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  20% {
    transform: rotate3d(0, 1, 0, 180deg);
  }
  40% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}
@keyframes animrotate-x {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  20% {
    transform: rotate3d(1, 0, 0, 180deg);
  }
  40% {
    transform: rotate3d(1, 0, 0, 360deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 360deg);
  }
}
@keyframes animrotate-z {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes animscale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>

