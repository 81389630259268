import state from "./state";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

import g2048 from "../g2048/store";
import signsPractice from "../signs-practice/store";
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    g2048,
    signsPractice,
  },
};
